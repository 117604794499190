import { fetch } from 'dva';
import { domain } from '../common/common.js';
import { notification } from 'antd';

const codeMessage = {
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '用户没有权限（令牌、用户名、密码错误）。',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。',
};

/* 请求错误状态提示 */
function checkStatus(response) {
  if (response.status >= 200 && response.status <= 400 || response.status === 404) {
    return response;
  }
  const errorText = codeMessage[response.status] || response.statusText;
  const error = new Error(errorText);
  error.name = response.status;
  error.response = response;
  throw error;
}

export default function request(url, options) {
  const defaultOptions = {
    credentials: 'include',
  };
  const newOptions = { ...defaultOptions, ...options };
  if (newOptions && newOptions.needToken === 'false') {
    //重置密码操作时不需要设置权限token
    newOptions.headers = {};
  } else if (newOptions && newOptions.withoutDomain) {
    newOptions.headers = {};
    newOptions.credentials = 'omit';
  } else {
    newOptions.headers = {
      'X-KND-FILE-FORMAT': 'KUID',  // 配合后台增加标识修改uid问题
      'X-KND-TIME-FORMAT': 'KNDV1', // 配合后台修改请求头部中的时间格式问题
      Authorization: `JWT ${localStorage.getItem('token')}`,
    };
  }

  const title = url.split('/')[0];

  if (!newOptions.withoutDomain) {
    if (newOptions.noUseEnterPriseID) {
      // 登录,忘记密码,无企业个人账户不使用企业id
      url = domain + url;
    } else {
      const enterpriseID = localStorage.getItem('enterpriseID');
      if(title && title === 'api'){
        let url2 = url.replace('api/','')
        let domains = domain.replace('api/v3.1','apix/v1')
        url = domains + 'enterprises/' + enterpriseID + '/' + url2;
      } else {
        url = domain + 'enterprises/' + enterpriseID + '/' + url;
      }
    }
  }

  if (
    newOptions.method === 'POST' ||
    newOptions.method === 'PUT' ||
    newOptions.method === 'PATCH'
  ) {
    if (!(newOptions.body instanceof FormData)) {
      newOptions.headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
        ...newOptions.headers,
      };
      newOptions.body = JSON.stringify(newOptions.body);
    } else {
      // newOptions.body is FormData
      newOptions.headers = {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        ...newOptions.headers,
      };
    }
  }

  return fetch(url, newOptions)
    .then(response => {
      checkStatus(response);
      // 需要做删除验证时,返回response; 不需要做删除验证时,返回response.status
      if (response.status === 204) {
        return response.status;
      }
      if (response.state === 400) {
        return response;
      }
      return response.json();
    })
    .catch(error => {
      const status = error.name;
      if (status === 401) {
        window.g_app._store.dispatch({
          type: 'login/logout',
        });
        return;
      }
      //给用户抛出异常提示
      if (status >= 400 && status <= 504) {
        notification.error({
          message: `请求错误: ${status}`,
          description: codeMessage[status],
        });
      }
      /* 禁止错误页面跳转
      if (status === 403) {
        history.push('/exception/403');
        return;
      }
      if (status <= 504 && status >= 500) {
        history.push('/exception/500');
        return;
      }
      if (status >= 404 && status < 422) {
        history.push('/exception/404');
      }
      */
    });
}
