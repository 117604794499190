import service from '../services/status';

export default {
  namespace: 'status',
  // 模型
  state: {},

  // 异步方法，主要从定义异步请求
  effects: {
    // 异步请求开机率chart数据
    *getChartList({ payload }, { call, put }) {
      const result = yield call(service.getChartList, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveChartList',
        result,
      });
    },
    *getChartListToday({ payload }, { call, put }) {
      const result = yield call(service.getChartListToday, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveChartListToday',
        result,
      });
    },
    *getChartListMonth({ payload }, { call, put }) {
      const result = yield call(service.getChartListMonth, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveChartListMonth',
        result,
      });
    },
    *getChartListYear({ payload }, { call, put }) {
      const result = yield call(service.getChartListYear, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveChartListYear',
        result,
      });
    },

    // 异步请求故障率数据列表
    *getFaultList({ payload }, { call, put }) {
      const result = yield call(service.getFaultList, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveFaultList',
        result,
      });
    },

    // 异步请求订单任务数据列表
    *getOrderTask({ payload }, { call, put }) {
      const result = yield call(service.getOrderTask, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveOrderTask',
        result,
      });
    },

    // 异步请求故障详情列表数据
    *getFaultDetailList({ payload }, { call, put }) {
      const result = yield call(service.getFaultDetailList, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      const data = result.data;
      const count = result.count;
      yield put({
        type: 'saveFaultDetailList',
        data,
        count,
      });
    },

    // 异步请求故障频率列表数据
    *getFaultRateList({ payload }, { call, put }) {
      const result = yield call(service.getFaultRateList, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveFaultRateList',
        result,
      });
    },

    // 异步请求产线目标件数和完成件数数据
    *getTotalTarget({ payload }, { call, put }) {
      const result = yield call(service.getTotalTarget, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveTotalTarget',
        result,
      });
    },
    // 异步请求产线实时产量数据
    *getDataOnTime({ payload }, { call, put }) {
      const result = yield call(service.getDataOnTime, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveDataOnTime',
        result,
      });
      return result;
    },

    // 异步请求累计产量数据api - 大屏
    *getTotalProduction({ payload }, { call, put }) {
      const result = yield call(service.getTotalProduction, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveDataTotal',
        result,
      });
    },
    // 异步请求今日实时产量数据api - 大屏
    *getRealTimeProduction({ payload }, { call, put }) {
      const result = yield call(service.getRealTimeProduction, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveDataRealTime',
        result,
      });
    },
    // 异步请求今日产量数据api - 大屏
    *getRealTimeTotalProduction({ payload }, { call, put }) {
      const result = yield call(service.getRealTimeTotalProduction, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveRealTimeTotalProduction',
        result,
      });
    },
    // 异步请求历史产量数据api - 大屏
    *getHistoricalProduction({ payload }, { call, put }) {
      const result = yield call(service.getHistoricalProduction, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveDataHistorical',
        result,
      });
    },
    // 异步请求即时报警信息 - 大屏
    *getCurrentEvent({ payload }, { call, put }) {
      const result = yield call(service.getCurrentEvent, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveCurrentEvent',
        result,
      });
    },
    // 异步请求当日报警信息 - 大屏
    *getTodayEvent({ payload }, { call, put }) {
      const result = yield call(service.getTodayEvent, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveTodayEvent',
        result,
      });
    },
    // 故障类型占比 - 大屏
    *getEventRate({ payload }, { call, put }) {
      const result = yield call(service.getEventRate, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveEventRate',
        result,
      });
    },

    // 1.异步请求产线我的任务数据
    *getProLineTask({ payload }, { call, put }) {
      const result = yield call(service.getProLineTask, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveProLineTask',
        result,
      });
    },
    // 异步请求产线任务完成数据
    *getProLineDone({ payload }, { call, put }) {
      const result = yield call(service.getProLineDone, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveProLineDone',
        result,
      });
    },
    // 2.异步请求产线效率-时间利用率数据
    *getProLineRate({ payload }, { call, put }) {
      const result = yield call(service.getProLineRate, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveProLineRate',
        result,
      });
    },
    // 异步请求产线效率-工序时间数据
    *getProLineOP({ payload }, { call, put }) {
      const result = yield call(service.getProLineOP, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveProLineOP',
        result,
      });
    },

    // 3.异步请求产线设备列表数据
    *getProLineDev({ payload }, { call, put }) {
      const result = yield call(service.getProLineDev, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveProLineDev',
        result,
      });
    },
    // 异步请求产线设备分类列表数据
    *getProLineDevType({ payload }, { call, put }) {
      const result = yield call(service.getProLineDevType, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveProLineDevType',
        result,
      });
      return result;
    },

    /* 异步请求单机设备分类列表 */
    *getUnitDevice({ payload }, { call, put }) {
      const result = yield call(service.getUnitDevice, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveUnitDevice',
        result,
      });
    },
    // 1.异步请求单机设备目标件数和完成件数数据
    *getDeviceGoal({ payload }, { call, put }) {
      const result = yield call(service.getDeviceGoal, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveDeviceGoal',
        result,
      });
    },
    // 1.异步请求单机设备实时产量数据
    *getDevDataOnTime({ payload }, { call, put }) {
      const result = yield call(service.getDevDataOnTime, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveDevDataOnTime',
        result,
      });
    },
    // 1.异步请求单机设备时间利用率
    *getDeviceTimeRate({ payload }, { call, put }) {
      const result = yield call(service.getDeviceTimeRate, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveDeviceTimeRate',
        result,
      });
    },
    // 1.异步请求单机设备运行时间分布
    *getDeviceDistribute({ payload }, { call, put }) {
      const result = yield call(service.getDeviceDistribute, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveDeviceDistribute',
        result,
      });
    },
    // 1.异步请求单机设备我的任务数据
    *getDeviceTask({ payload }, { call, put }) {
      const result = yield call(service.getDeviceTask, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveDeviceTask',
        result,
      });
    },
    // 2.异步请求单机设备任务完成数据
    *getDeviceDone({ payload }, { call, put }) {
      const result = yield call(service.getDeviceDone, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveDeviceDone',
        result,
      });
    },
    // 异步请求单机设备历史产量数据
    *getDeviceHistory({ payload }, { call, put }) {
      const result = yield call(service.getDeviceHistory, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveDeviceHistory',
        result,
      });
      return result;
    },

    // 3.1 异步请求单机设备日志表格数据
    *getDeviceLogList({ payload }, { call, put }) {
      const result = yield call(service.getDeviceLog, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      const data = result.results;
      const count = result.count;
      const counting = result.counting;
      yield put({
        type: 'saveDeviceLogList',
        data,
        count,
        counting
      });
    },
    // 要导出的数据
    *getDeviceLogData({ payload }, { call, put }) {
      const result = yield call(service.getDeviceLog, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      const data = result.results;
      const page = result.page;
      const size = result.size;
      const count = result.count;
      yield put({
        type: 'saveDeviceLogData',
        data,
        page,
        size,
        count,
      });
    },

    // 3.15 异步请求单机设备运行状态数据
    *getDeviceStatusChart({ payload }, { call, put }) {
      const result = yield call(service.getDeviceStatusChart, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveDeviceStatus',
        result,
      });
      return result;
    },
    // 3.2 异步请求单机设备报警记录表格数据
    *getDeviceAlarm({ payload }, { call, put }) {
      const result = yield call(service.getDeviceAlarm, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      const data = result.data;
      const count = result.count;
      yield put({
        type: 'saveDeviceAlarm',
        data,
        count,
      });
    },
    // 要导出的数据
    *getDeviceAlarmData({ payload }, { call, put }) {
      const result = yield call(service.getDeviceAlarm, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      const data = result.data;
      const count = result.count;
      const page = result.page;
      const size = result.size;
      yield put({
        type: 'saveDeviceAlarmData',
        data,
        page,
        size,
        count,
      });
    },
    // 异步请求单机设备报警记录chart数据
    *getAlarmChart({ payload }, { call, put }) {
      const result = yield call(service.getAlarmChart, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveAlarmChart',
        result,
      });
    },
    // 异步请求单机设备维修记录chart数据
    *getServiceChart({ payload }, { call, put }) {
      const result = yield call(service.getServiceChart, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveServiceChart',
        result,
      });
    },

    // 异步请求人员报修记录表格数据（增删改查）
    *getDeviceDefault({ payload }, { call, put }) {
      const result = yield call(service.getDeviceDefault, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveDeviceDefault',
        result,
      });
    },
    *getDeviceDefaultSearch({ payload }, { call, put }) {
      const result = yield call(service.getDeviceDefaultSearch, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveDeviceDefaultSearch',
        result,
      });
    },
    *createDeviceDefault({ payload }, { call, put }) {
      const result = yield call(service.createDeviceDefault, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'createDeviceDefaultList',
        result,
      });
    },
    *updateDeviceDefault({ payload }, { call, put }) {
      const result = yield call(service.updateDeviceDefault, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'updateDeviceDefaultList',
        result,
      });
    },
    *deleteDeviceDefault({ payload }, { call, put }) {
      const result = yield call(service.deleteDeviceDefault, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      if (result) {
        yield put({
          type: 'deleteDeviceDefaultList',
          result: payload,
        });
      }
    },

    // 异步请求单机设备维修记录表格数据（删改查）
    *getDeviceService({ payload }, { call, put }) {
      const result = yield call(service.getDeviceService, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveDeviceService',
        result,
      });
    },
    *creatDeviceService({ payload }, { call, put }) {
      const result = yield call(service.creatDeviceService, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'createDeviceServiceList',
        result,
      });
    },
    *updateDeviceService({ payload }, { call, put }) {
      const result = yield call(service.updateDeviceService, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'updateDeviceServiceList',
        result,
      });
    },
    *deleteDeviceService({ payload }, { call, put }) {
      const result = yield call(service.deleteDeviceService, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      if (result) {
        yield put({
          type: 'deleteDeviceServiceList',
          result: payload,
        });
      }
    },

    // 异步请求人员维修记录表格数据（增删改查）
    *getDeviceMaintain({ payload }, { call, put }) {
      const result = yield call(service.getDeviceMaintain, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveDeviceMaintain',
        result,
      });
    },
    // 维修记录表格增删改
    *creatDeviceMaintain({ payload }, { call, put }) {
      const result = yield call(service.creatDeviceMaintain, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'createDeviceMaintainList',
        result,
      });
    },
    *updateDeviceMaintain({ payload }, { call, put }) {
      const result = yield call(service.updateDeviceMaintain, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'updateDeviceMaintainList',
        result,
      });
    },
    *deleteDeviceMaintain({ payload }, { call, put }) {
      const result = yield call(service.deleteDeviceMaintain, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      if (result) {
        yield put({
          type: 'deleteDeviceMaintainList',
          result: payload,
        });
      }
    },


    // 大屏新增接口
    // 累计加工时间
    *getDeviceWorkTime({ payload }, { call, put }) {
      const result = yield call(service.getDeviceWorkTime, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveDeviceWorkTime',
        result,
      });
    },
    // 累计空闲时间
    *getDeviceIdleTime({ payload }, { call, put }) {
      const result = yield call(service.getDeviceIdleTime, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveDeviceIdleTime',
        result,
      });
    },
    // 累计报警时间
    *getDeviceAlarmTime({ payload }, { call, put }) {
      const result = yield call(service.getDeviceAlarmTime, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveDeviceAlarmTime',
        result,
      });
    },
    // 累计加工件数
    *getDeviceCount({ payload }, { call, put }) {
      const result = yield call(service.getDeviceAccumulationCount, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveDeviceCount',
        result,
      });
    },

    //查看生产单元状态
    *getUnitsStatus({ payload }, { call, put }) {
      const result = yield call(service.getUnitsStatus, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveUnitsStatus',
        result,
      });
      return result;
    },
  },

  // 同步方法，主要讲数据更新到state中
  reducers: {
    // mock数据
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },

    // 图表数据 ChartData
    saveChartList(state, { result: ChartData }) {
      return { ...state, ChartData };
    },
    saveChartListToday(state, { result: ChartTodayData }) {
      return { ...state, ChartTodayData };
    },
    saveChartListMonth(state, { result: ChartMonthData }) {
      return { ...state, ChartMonthData };
    },
    saveChartListYear(state, { result: ChartYearData }) {
      return { ...state, ChartYearData };
    },

    // 故障率 FaultData
    saveFaultList(state, { result: FaultData }) {
      return { ...state, FaultData };
    },
    // 订单任务数据 OrderTaskData
    saveOrderTask(state, { result: OrderTaskData }) {
      return { ...state, OrderTaskData };
    },

    // 故障详情数据 FaultDetailData
    saveFaultDetailList(state, { data: FaultDetailData, count }) {
      return {
        ...state,
        FaultDetailData,
        count,
      };
    },
    // 故障频率数据 FaultRateData
    saveFaultRateList(state, { result: FaultRateData }) {
      return { ...state, FaultRateData };
    },

    // 车间对应产线数据
    saveSecProLineList(state, { result: SecProLineList }) {
      return { ...state, SecProLineList };
    },

    // 1.产线目标件数和完成件数
    saveTotalTarget(state, { result: TotalTargetData }) {
      return { ...state, TotalTargetData };
    },
    // 产线实时产量数据
    saveDataOnTime(state, { result: OnTimeData }) {
      return { ...state, OnTimeData };
    },

    // 今日实时产量数据大屏
    saveDataRealTime(state, { result: data }) {
      const DataRealTime = data?.data;
      const DataRealTimePage = data?.page;
      return { ...state, DataRealTime, DataRealTimePage };
    },
    // 今日产量数据大屏
    saveRealTimeTotalProduction(state, { result: RealTimeTotalProduction }) {
      return { ...state, RealTimeTotalProduction };
    },
    // 历史产量数据大屏
    saveDataHistorical(state, { result: DataHistorical }) {
      return { ...state, DataHistorical };
    },
    // 累计产量数据大屏
    saveDataTotal(state, { result: DataTotal }) {
      return { ...state, DataTotal };
    },
    // 报警即时信息数据
    saveCurrentEvent(state, { result: DataCurrentEvent }) {
      return { ...state, DataCurrentEvent };
    },
    // 报警当日信息数据
    saveTodayEvent(state, { result: DataTodayEvent }) {
      return { ...state, DataTodayEvent };
    },
    // 故障类型占比数据
    saveEventRate(state, { result: DataEventRate }) {
      return { ...state, DataEventRate };
    },

    // 产线我的任务数据
    saveProLineTask(state, { result: data }) {
      const LineTaskData = data.filter(
        item => item.status === '待执行' || item.status === '执行中' || item.status === '暂停'
      );
      return { ...state, LineTaskData };
    },
    // 产线任务完成数据
    saveProLineDone(state, { result: LineDoneData }) {
      return { ...state, LineDoneData };
    },
    // 2.产线我的效率数据-时间利用率
    saveProLineRate(state, { result: LineRateData }) {
      return { ...state, LineRateData };
    },
    // 产线我的效率数据-工序时间
    saveProLineOP(state, { result: LineOPData }) {
      return { ...state, LineOPData };
    },
    // 3.产线设备列表数据
    saveProLineDev(state, { result: LineDevData }) {
      return { ...state, LineDevData };
    },
    // 产线无分类设备列表数据
    saveProLineDevType(state, { result: LineDevTypeData }) {
      return { ...state, LineDevTypeData };
    },

    // 单机设备列表数据
    saveUnitDevice(state, { result: UnitDeviceData }) {
      return { ...state, UnitDeviceData };
    },
    // 单机设备目标件数和完成件数
    saveDeviceGoal(state, { result: DeviceGoalData }) {
      return { ...state, DeviceGoalData };
    },
    // 单机设备实时产量数据
    saveDevDataOnTime(state, { result: OnTimeDevData }) {
      return { ...state, OnTimeDevData };
    },
    // 单机设备时间利用率
    saveDeviceTimeRate(state, { result: TimeRateDevData }) {
      return { ...state, TimeRateDevData };
    },
    // 单机设备运行时间分布
    saveDeviceDistribute(state, { result: TimeDisDevData }) {
      return { ...state, TimeDisDevData };
    },

    // 1.单机设备我的任务数据
    saveDeviceTask(state, { result: data }) {
      const DeviceTaskData = data.filter(
        item => item.status === '待执行' || item.status === '执行中'
      );
      return { ...state, DeviceTaskData };
    },

    // 2.单机设备任务完成数据
    saveDeviceDone(state, { result: DeviceDoneData }) {
      return { ...state, DeviceDoneData };
    },
    // 单机设备历史产量数据
    saveDeviceHistory(state, { result: DeviceHisData }) {
      return { ...state, DeviceHisData };
    },

    // 3.1 单机设备日志表格数据
    saveDeviceLogList(state, { data: DeviceLogList, count, counting }) {
      return {
        ...state,
        DeviceLogList,
        count,
        counting
      };
    },
    saveDeviceLogData(state, { data: DeviceLogData, page, size, count }) {
      return {
        ...state,
        DeviceLogData,
        count,
        page,
        size,
      };
    },
    // 3.15单机设备运行状态数据
    saveDeviceStatus(state, { result: DeviceStaData }) {
      return { ...state, DeviceStaData };
    },
    // 3.2 单机设备报警记录表格数据
    saveDeviceAlarm(state, { data: DeviceAlarmData, count }) {
      return {
        ...state,
        DeviceAlarmData,
        count,
      };
    },
    saveDeviceAlarmData(state, { data: DeviceAlarmListData, page, size, count }) {
      return {
        ...state,
        DeviceAlarmListData,
        count,
        page,
        size,
      };
    },
    // 单机设备报警记录chart数据
    saveAlarmChart(state, { result: AlarmChartData }) {
      return { ...state, AlarmChartData };
    },
    // 单机设备维修记录chart数据
    saveServiceChart(state, { result: ServiceChartData }) {
      return { ...state, ServiceChartData };
    },

    // 人员报修记录表格数据
    saveDeviceDefault(state, { result: DeviceDefaultData }) {
      return { ...state, DeviceDefaultData };
    },
    saveDeviceDefaultSearch(state, { result: DeviceDefaultSearchData }) {
      return { ...state, DeviceDefaultSearchData };
    },
    createDeviceDefaultList(state, { result: DeviceDefaultData }) {
      state.DeviceDefaultData = [DeviceDefaultData].concat(state.DeviceDefaultData);
      return {
        ...state,
      };
    },
    updateDeviceDefaultList(state, { result }) {
      state.DeviceDefaultData = state.DeviceDefaultData.map(item => {
        return item.id === result.id ? result : item;
      });
      return { ...state };
    },
    deleteDeviceDefaultList(state, { result: payload }) {
      state.DeviceDefaultData = state.DeviceDefaultData.filter(
        item => item.id !== payload.id
      );
      return {
        ...state,
      };
    },

    // 单机设备维修记录表格数据（删改查DeviceServiceData）
    saveDeviceService(state, { result: DeviceServiceData }) {
      return { ...state, DeviceServiceData };
    },
    createDeviceServiceList(state, { result: DeviceServiceData }) {
      state.DeviceServiceData = [DeviceServiceData].concat(state.DeviceServiceData);
      return { ...state };
    },
    updateDeviceServiceList(state, { result }) {
      state.DeviceServiceData = state.DeviceServiceData.map(item => {
        return item.id === result.id ? result : item;
      });
      return { ...state };
    },
    deleteDeviceServiceList(state, { result: payload }) {
      state.DeviceServiceData = state.DeviceServiceData.filter(
        item => item.id !== payload.id
      );
      return {
        ...state,
      };
    },

    // 人员维修记录表格数据（增删改查DeviceMaintainData）
    saveDeviceMaintain(state, { result: DeviceMaintainData }) {
      return { ...state, DeviceMaintainData };
    },
    createDeviceMaintainList(state, { result: DeviceMaintainData }) {
      state.DeviceMaintainData = [DeviceMaintainData].concat(state.DeviceMaintainData);
      return { ...state };
    },
    updateDeviceMaintainList(state, { result }) {
      state.DeviceMaintainData = state.DeviceMaintainData.map(item => {
        return item.id === result.id ? result : item;
      });
      return { ...state };
    },
    deleteDeviceMaintainList(state, { result: payload }) {
      state.DeviceMaintainData = state.DeviceMaintainData.filter(
        item => item.id !== payload.id
      );
      return {
        ...state,
      };
    },



    // 大屏新增接口数据
    // 累计加工时间
    saveDeviceWorkTime(state, { result: DeviceWorkTimeData }) {
      return { ...state, DeviceWorkTimeData };
    },
    // 累计空闲时间
    saveDeviceIdleTime(state, { result: DeviceIdleTimeData }) {
      return { ...state, DeviceIdleTimeData };
    },
    // 累计报警时间
    saveDeviceAlarmTime(state, { result: DeviceAlarmTimeData }) {
      return { ...state, DeviceAlarmTimeData };
    },
    // 累计加工件数
    saveDeviceCount(state, { result: DeviceCountData }) {
      return { ...state, DeviceCountData };
    },
  },
};
