import { setAuthority } from '@/utils/authority';
import { reloadAuthorized } from '@/utils/Authorized';
import service from '@/services/login';
import { routerRedux } from 'dva';

export default {
  namespace: 'login',
  state: {},

  effects: {
    *login({ payload }, { call, put }) {
      const response = yield call(service.fakeAccountLogin, payload);
      // Login successfully
      if (response && response.token) {
        // response 包含authority、token字段。前端需要获得authority字段来获取权限
        yield put({
          type: 'changeLoginStatus',
        });

        localStorage.setItem('token', response.token);
        localStorage.setItem('tokenLogin', payload.auto_login);
      } else {
        // 登录失败
        if (response && response.error && response.error.code == 3) { // response.non_field_errors
          yield put({
            type: 'changeLoginErrorStatus',
          });
        } else if (response && response.more_account) {
          yield put({
            type: 'changeLoginMoreAccountStatus',
          });
        }
      }
    },

    *getTokenBySignature({ payload }, { call, put }) {
      const data = yield call(service.getTokenBySignature, payload);
      if (data && data.token) {
        localStorage.setItem('token', data.token);
        localStorage.setItem('tokenLogin', true);
        yield put({
          type: 'saveSignatureToken',
          data,
        });
      };
    },

    /* 保存登录人员企业信息 */
    *fetchCompanyList({ payload }, { call, put }) {
      const result = yield call(service.fetchUserInfo, payload);
      const data = result.enterprise;
      yield put({
        type: 'saveCompaniesData',
        data,
      });
    },

    // 获取登录人员上下线信息
    *fetchUserStatusInfo({ payload }, { call, put }) {
      const result = yield call(service.fetchUserStatusInfo, payload);
      yield put({
        type: 'saveUserStatusInfo',
        result
      });
      return result;
    },
    /* 保存登录人员信息存localstorage并跳转 */
    *fetchUserInfo({ payload }, { call, put }) {
      const result = yield call(service.fetchUserInfo, payload);
      yield put({
        type: 'saveUserInfoRes',
        payload,
      });
      localStorage.setItem('userId', result.id);
      localStorage.setItem('userName', result.name);
      localStorage.setItem('adminMain', result.admin);

      // 记住上一次登录的企业（上次缓存的企业信息currentKey）
      let currentKey = localStorage.getItem('currentKey'), enterpriseID, group, type, system, is_standard;
      if (currentKey && currentKey.split("-").length > 1) {
        enterpriseID = currentKey && currentKey.split("-")[0];
        group = currentKey && currentKey.split("-")[1];
        type = currentKey && currentKey.split("-")[2];

        setAuthority(group); // 授权登录
        localStorage.setItem('currentKey', currentKey);
        localStorage.setItem('enterpriseID', enterpriseID);
        localStorage.setItem('enterType', type);

        if (payload && payload.redirectUrl) {
          yield put(routerRedux.push(payload.redirectUrl));
        } else {
          if (type == 0) { // 制造商
            yield put(routerRedux.push('/device-status/'));
          }
          if (type == 1) { // 服务商
            yield put(routerRedux.push('/remote-connect'));
          }
          if (type == 2) { // 企业
            if (group === 'operator') {
              yield put(routerRedux.push('/workbench/task-missions'));
            } else {
              yield put(routerRedux.push('/main-page'));
            }
          }
        }
      } else {
        currentKey = `${result.enterprise[0].id}-${result.enterprise[0].group}-${result.enterprise[0].type}`;
        enterpriseID = result.enterprise && result.enterprise[0].id;
        group = result.enterprise && result.enterprise[0].group;
        type = result.enterprise && result.enterprise[0].type;
        system = result.enterprise && result.enterprise[0].system;
        is_standard = result.enterprise && result.enterprise[0].is_standard;

        setAuthority(group); // 授权登录
        localStorage.setItem('currentKey', currentKey);
        localStorage.setItem('enterpriseID', enterpriseID);
        localStorage.setItem('enterType', type);
        localStorage.setItem('system', system);
        localStorage.setItem('is_standard',is_standard)

        if (payload && payload.redirectUrl) {
          yield put(routerRedux.push(payload.redirectUrl));
        } else {
          if (type == 0) { // 制造商
            yield put(routerRedux.push('/device-status/'));
          }
          if (type == 1) { // 服务商
            yield put(routerRedux.push('/remote-connect'));
          }
          if (type == 2) { // 企业
            if (group === 'operator') {
              yield put(routerRedux.push('/workbench/task-missions'));
            } else {
              yield put(routerRedux.push('/main-page'));
            }
          }
        }
      }
      reloadAuthorized();
      return result
    },

    *logout(_, { put }) {
      reloadAuthorized();
      // 退出时清空localstorage
      const tokenLogin = localStorage.getItem("tokenLogin");
      if (tokenLogin === 'true') {
        Object.keys(localStorage).forEach(item =>
          (item.indexOf('tokenLogin') === -1) ? localStorage.removeItem(item) : ''
        );
      } else {
        localStorage.clear()
      }
      yield put(routerRedux.push('/user/login'));
    },

    *checkPhone({ payload }, { call, put }) {
      const result = yield call(service.checkPhone, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveCheckPhoneRes',
        result,
      });
    },

    *changePwdSubmit({ payload }, { call, put }) {
      const result = yield call(service.changePwdSubmit, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'savePwdSubmitRes',
        result,
      });
    },
  },

  reducers: {
    /* 正确的登录状态 (返回的response没有status和type字段，只能手动判断) */
    changeLoginStatus(state) {
      return {
        ...state,
        status: 'ok',
        type: 'account',
      };
    },
    /* 账户密码错误的登录状态 */
    changeLoginErrorStatus(state) {
      return {
        ...state,
        status: 'error',
        type: 'account',
      };
    },
    /* 多账户禁止登录的登录状态 */
    changeLoginMoreAccountStatus(state) {
      return {
        ...state,
        status: 'moreAccount',
        type: 'account',
      };
    },

    /* 保存通过signature获取的token */
    saveSignatureToken(state, { data: SignatureToken }) {
      return { ...state, SignatureToken };
    },

    /* 保存登录人员所有企业信息 */
    saveCompaniesData(state, { data: CompanyListData }) {
      return { ...state, CompanyListData };
    },
    /* 保存登录人员信息并跳转 */
    saveUserInfoRes(state) {
      return {
        ...state,
      };
    },
    /* 数据库中是否存在该手机号 */
    saveCheckPhoneRes(state, { result: CheckPhoneMsg }) {
      return {
        ...state,
        CheckPhoneMsg,
      };
    },
    /* 提交修改密码 */
    savePwdSubmitRes(state, { result: PwdSubmitMsg }) {
      return {
        ...state,
        PwdSubmitMsg,
      };
    },
  },
};
