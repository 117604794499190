import { stringify } from 'qs'; // 方法引入
import request from '../utils/request'; // 引入ajax请求方法

export default {
  // 订单剩余件数
  getPieceRemain(params) {
    return request(`order/${params.id}/piece-remain/`, {
      method: 'GET',
    });
  },

  // 任务分页列表api
  getTaskList(params) {
    return request(`task/?${stringify(params)}`, {
      method: 'GET',
    });
  },
  // 任务所有列表api
  getAllTaskList() {
    return request('task/status/', {
      method: 'GET',
    });
  },

  //任务编号重复检查
  checkTaskNumber(params) {
    return request(`task/number/check`, {
      method: 'POST',
      body: params,
    })
  },
  // 创建任务api
  createTask(params) {
    return request('task/', {
      method: 'POST',
      body: params,
    });
  },
  // 修改任务api
  getTaskUpdate(params) {
    return request(`task/${params.id}/`, {
      method: 'PATCH',
      body: params,
    });
  },
  // 删除任务api
  getTaskDelete(id) {
    return request(`task/${id}/`, {
      method: 'DELETE',
    });
  },
  // 查看任务api
  getTaskDetail(id) {
    return request(`task/${id}/`, {
      method: 'GET',
    });
  },
  // 查看任务进度api
  taskSpeedData(id) {
    return request(`task/${id}/log/`, {
      method: 'GET',
    });
  },
  // 查看测量表数据api
  getTaskMeasureData(params) {
    let id = params?.id;
    let paramsone = {
      page: params.page,
      size: params.size,
      date: params.date,
    }
    return request(`task/${id}/component_measure/?${stringify(paramsone)}`, {
      method: 'GET',
    });
  },
  // 更改测量表数据
  updateTaskMeasureData(params) {
    return request(`task/${params?.task_id}/component_measure/${params?.id}`, {
      method: 'PATCH',
      body: params,
    });
  },
  // 删除测量表数据api
  deleteTaskMeasureData(params) {
    return request(`task/${params?.task_id}/component_measure/${params?.id}`, {
      method: 'DELETE',
    });
  },
  // 修改任务进度api
  updateTaskSpeed(params) {
    return request(`task/${params.id}/log/${params.logId}/`, {
      method: 'PATCH',
      body: params,
    });
  },

  // 订单分页列表api
  getOrderList(params) {
    return request(`order/?${stringify(params)}`, {
      method: 'GET',
    });
  },
  // 订单所有列表api
  getAllOrderList() {
    return request('order/status/', {
      method: 'GET',
    });
  },
  OrderNumTest(params) {
    return request(`order/check`, {
      method: 'POST',
      body: params,
    });
  },
  // 创建订单api
  createOrder(params) {
    return request('order/', {
      method: 'POST',
      body: params,
    });
  },
  // 删除订单api
  deleteOrder(id) {
    return request(`order/${id}/`, {
      method: 'DELETE',
    });
  },
  // 修改订单api
  updateOrder(params) {
    return request(`order/${params.id}/`, {
      method: 'PATCH',
      body: params,
    });
  },
  // 查看订单api
  orderDetail(id) {
    return request(`order/${id}/`, {
      method: 'GET',
    });
  },
  // 查看订单进度api
  orderSpeedData(id) {
    return request(`order/${id}/log/`, {
      method: 'GET',
    });
  },

  // 获取操作人员动态
  getUserTrends(params) {
    return request(`users/log/?${stringify(params)}`, {
      method: 'GET',
    });
  },

  // 操作员权限接口
  // 获取任务日志列表
  getTaskLogList() {
    return request('users/production_work/', {
      method: 'GET',
    });
  },
  // 异步新增任务日志
  createTaskLog(params) {
    return request('users/production_work/', {
      method: 'POST',
      body: params,
    });
  },
  // 异步更新任务日志
  updateTaskLog(params) {
    return request(`users/production_work/${params.id}/`, {
      method: 'PATCH',
      body: params,
    });
  },
  // 异步删除任务日志
  deleteTaskLog(id) {
    return request(`users/production_work/${id}/`, {
      method: 'DELETE',
    });
  },

  // 管理员权限订单任务接口
  // 获取订单任务列表
  getOrderTasksList(order_id) {
    return request(`order/${order_id}/tasks`, {
      method: 'GET',
    });
  },
  // 获取订单日志列表
  getOrderLogData(params) {
    return request(`order/${params.id}/production_log/?page=${params.page}&size=${params.size}`, {
      method: 'GET',
    });
  },
  // 获取任务日志列表
  getTaskLogData(params) {
    return request(`task/${params.id}/production_log/?page=${params.page}&size=${params.size}`, {
      method: 'GET',
    });
  },
  // 修改任务日志列表
  updateTaskLogData(params) {
    return request(`task/${params.id}/production_log/`, {
      method: 'PATCH',
      body: params,
    });
  },

  // 新增大屏接口
  // 异步请求即将交付订单列表
  getDeadlineOrderList() {
    return request('order/?status=deadline', {
      method: 'GET',
    });
  },
  // 异步请求即将过期任务列表
  getDeadlineTaskList() {
    return request('task/?status=deadline', {
      method: 'GET',
    });
  },
  // 异步请求90天内订单状态
  getOrderStatus() {
    return request('company/order_status/', {
      method: 'GET',
    });
  },
  // 异步请求30天内任务状态
  getTaskStatus() {
    return request('company/task_status/', {
      method: 'GET',
    });
  },
  // 异步30天内任务列表
  getMonthTaskList() {
    return request('task/?days=30', {
      method: 'GET',
    });
  },
  // 异步30天内任务数量统计
  getMonthTaskCount() {
    return request('company/task_count/', {
      method: 'GET',
    });
  },

  //任务件数下发cnc
  createCncCount(params){
    return request(`api/units/${params.id}/publish_target_number/`, {
      method: 'POST',
      body: {target_number: params.target_number}
    });
  }
};
