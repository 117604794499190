import { stringify } from 'qs'; // 方法引入
import request from '../utils/request';
import { fetch } from 'dva';
const changeDate = params => {
  switch (params.type) {
    case 'week':
      return 'days=7';
    case 'month':
      return 'days=30';
    case 'year':
      return 'days=90';
    case 'dates':
      return `interval=${params.dateRange[0]}~${params.dateRange[1]}`;
  }
};
export default {
  // 异步请求版本信息
  getVersionTxt() {
    return request('/version.json', {
      method: 'GET',
      withoutDomain: true,
    });
  },

  // 获取企业日产量统计列表（海力特）
  getStatisticList(params) {
    return request(`daily_production?${stringify(params)}`, {
      method: 'GET',
    });
  },
  //获取企业所有设备历史产量列表
  getStatisticDeviceHistory(params) {
    switch (params.type) {
      case 'week':
        return request(`units/production_work_count/?days=7`, {
          method: 'GET',
        });
      case 'month':
        return request(`units/production_work_count/?days=30`, {
          method: 'GET',
        });
      case 'year':
        return request(`units/production_work_count/?days=90`, {
          method: 'GET',
        });
      case 'dates':
        if (params.types === 'hour') {
          return request(
            `units/production_work_count/?timeinter=${params.dateRange[0]}~${params.dateRange[1]
            }&interval=60`,
            {
              method: 'GET',
            }
          );
        }
        if (params.types === 'day') {
          return request(
            `units/production_work_count/?interval=${params.dateRange[0]}~${params.dateRange[1]}`,
            {
              method: 'GET',
            }
          );
        }
      case 'search':
        return request(`units/production_work_count/?${stringify(params.data)}`, {
          method: 'GET',
        });
      default: break;
    }
  },
  //获取企业所有产线历史产量列表
  getStatisticProductLineHistory(params) {
    switch (params.type) {
      case 'week':
        return request(`product_line/history_production_volume/?days=7`, {
          method: 'GET',
        });
      case 'month':
        return request(`product_line/history_production_volume/?days=30`, {
          method: 'GET',
        });
      case 'year':
        return request(`product_line/history_production_volume/?days=90`, {
          method: 'GET',
        });
      case 'dates':
        if (params.types === 'hour') {
          return request(
            `product_line/history_production_volume/?timeinter=${params.dateRange[0]}~${params.dateRange[1]
            }&interval=60`,
            {
              method: 'GET',
            }
          );
        }
        if (params.types === 'day') {
          return request(
            `product_line/history_production_volume/?timeinter=${params.dateRange[0]}~${params.dateRange[1]
            }&interval=1440`,
            {
              method: 'GET',
            }
          );
        }
      default: break;
    }
  },
  //获取日产量详情
  getDailyProductYieldsInfoHistory(params) {
    switch (params.type) {
      case 'week':
        return request(`daily_product_yields_info/?days=7`, {
          method: 'GET',
        });
      case 'month':
        return request(`daily_product_yields_info/?days=30`, {
          method: 'GET',
        });
      case 'year':
        return request(`daily_product_yields_info/?days=90`, {
          method: 'GET',
        });
      case 'dates':
        if (params.types === 'hour') {
          return request(
            `daily_product_yields_info/?timeinter=${params.dateRange[0]}~${params.dateRange[1]
            }&interval=60`,
            {
              method: 'GET',
            }
          );
        }
        if (params.types === 'day') {
          return request(
            `daily_product_yields_info/?timeinter=${params.dateRange[0]}~${params.dateRange[1]
            }&interval=1440`,
            {
              method: 'GET',
            }
          );
        }
      default: break;
    }
  },
  //获取人员历史产量
  getUsersProductHistory(params) {
    switch (params.type) {
      case 'week':
        return request(`users/production_work_count/?days=7`, {
          method: 'GET',
        });
      case 'month':
        return request(`users/production_work_count/?days=30`, {
          method: 'GET',
        });
      case 'year':
        return request(`users/production_work_count/?days=90`, {
          method: 'GET',
        });
      case 'dates':
        if (params.types === 'hour') {
          return request(
            `users/production_work_count/?timeinter=${params.dateRange[0]}~${params.dateRange[1]
            }&interval=60`,
            {
              method: 'GET',
            }
          );
        }
        if (params.types === 'day') {
          return request(
            `users/production_work_count/?interval=${params.dateRange[0]}~${params.dateRange[1]
            }&search=${params.search}`,
            {
              method: 'GET',
            }
          );
        }
      case 'search':
        return request(`users/production_work_count/?${stringify(params.data)}`, {
          method: 'GET',
        });
      default: break;
    }
  },
  //获取人员生产日志列表
  getUserProductionLog(params) {
    return request(`users/production_record/?${stringify(params)}`, {
      method: 'GET',
    });
  },
  //获取人员某日生产日志列表
  getUserDailyProductionLog(params) {
    return request(`users/production_record/detail/?${stringify(params)}`, {
      method: 'GET',
    });
  },
  //获取产线日志列表
  getProductLineLogs(params) {
    return request(`product_line/${params.productLineId}/log/?${stringify(params.data)}`)
  },
  //获取产线累计运行状态列表
  getProductLineOpeStatus(params) {
    return request(`product_line/${params.productLineId}/accumulation/time/`)
  },
  //获取设备产量统计列表
  getProductYields(params) {
    return request(`units/production_work_count_info/?${stringify(params)}`, {
      method: 'GET',
    });
  },
  //获取日产量统计列表
  getDailyProductYieldsInfoHistory(params) {
    return request(`daily_product_yields_info/?${stringify(params)}`, {
      method: 'GET',
    });
  },
  //获取人员产量统计列表
  getUsersProductionWorkCount(params) {
    return request(`users/production_work_count_info/?${stringify(params)}`, {
      method: 'GET',
    });
  },
  //获取人员某日产量统计列表
  getUsersProductionWorkDetail(params) {
    return request(`users/production_work_count_detail/?${stringify(params)}`, {
      method: 'GET',
    });
  },
  //获取某日在线设备产量统计列表
  getOneDayProductYields(params) {
    return request(`units/production_work_count_detail/?${stringify(params)}`, {
      method: 'GET',
    });
  },
  //获取某日离线设备产量统计列表
  getOneDayOffProductYields(params) {
    return request(`units/offline_detail/?${stringify(params)}`, {
      method: 'GET',
    });
  },
  //获取企业班组产量统计列表
  getDailyProductYields(params) {
    switch (params.type) {
      case 'week':
        return request(`daily_product_yields/?days=7&search=${params?.search}`, {
          method: 'GET',
        });
      case 'month':
        return request(`daily_product_yields/?days=30&search=${params?.search}`, {
          method: 'GET',
        });
      case 'year':
        return request(`daily_product_yields/?days=90&search=${params?.search}`, {
          method: 'GET',
        });
      case 'dates':
        if (params.types === 'hour') {
          return request(
            `daily_product_yields/?date=${params.date}`,
            {
              method: 'GET',
            }
          );
        }
        if (params.types === 'day') {
          return request(
            `daily_product_yields/?interval=${params.dateRange[0]}~${params.dateRange[1]}&search=${params.search}`,
            {
              method: 'GET',
            }
          );
        };
      default: break;
    }
  },
  // 获取当前产线状态详情
  getProductLineStatus(params) {
    return request(`product_line/${params.productionId}/status`, {
      method: 'GET',
    });
  },
  // 获取当前产线今日加工件数
  getProductLineTodayFinish(params) {
    return request(`product_line/${params.productionId}/today/finished`, {
      method: 'GET',
    });
  },
  //获取某日任务产量列表
  getDailyProductTaskYields(params) {
    return request(`daily_product_task_yields/?${stringify(params)}`, {
      method: 'GET',
    });
  },
  //获取某日非加工任务产量列表
  getDailyProductNonTaskYields(params) {
    return request(`daily_product_non_task_yields/?${stringify(params)}`, {
      method: 'GET',
    });
  },
  //获取所有任务编号列表
  getAllTaskNumber(params) {
    return request(`tasks/search/`, {
      method: 'GET',
    })
  },
  //获取所有人员姓名
  getAllUsersName(params) {
    return request(`users/search/`, {
      method: 'GET',
    })
  },

  // 角色列表api
  getGroupList() {
    return request('users/group', {
      method: 'GET',
    });
  },
  // 公司和部门列表
  getCompanyTeam(params) {
    return request(`users/company_department_team/?${stringify(params)}`, {
      method: 'GET'
    })
  },
  //企业列表
  getCompaniesList() {
    return request(`users/companies/`, {
      method: 'GET'
    })
  },
  //创建企业列表
  createCompaniesList(params) {
    return request(`users/companies/`, {
      method: 'POST',
      body: params
    })
  },
  updateCompaniesList(params) {
    return request(`users/companies/${params.id}`, {
      method: 'PATCH',
      body: params
    })
  },
  deleteCompaniesList(params) {
    return request(`users/companies/${params.id}`, {
      method: 'DELETE',
      body: params
    })
  },
  //部门列表
  getCompaniesDepartmentsList(params) {
    return request(`users/companies/${params.company_id}/departments/`, {
      method: 'GET'
    })
  },
  //创建部门列表
  createCompaniesDepartmentsList(params) {
    return request(`users/companies/${params.company_id}/departments/`, {
      method: 'POST',
      body: params
    })
  },
  updateCompaniesDepartmentsList(params) {
    return request(`users/companies/${params.company_id}/departments/${params.id}`, {
      method: 'PATCH',
      body: params
    })
  },
  deleteCompaniesDepartmentsList(params) {
    return request(`users/companies/${params.company_id}/departments/${params.id}`, {
      method: 'DELETE',
      body: params
    })
  },
  //班组列表
  getComDepTeamsList(params) {
    return request(`users/companies/${params.company_id}/departments/${params.department_id}/teams/`, {
      method: 'GET'
    })
  },
  //创建班组列表
  createComDepTeamsList(params) {
    return request(`users/companies/${params.company_id}/departments/${params.department_id}/teams/`, {
      method: 'POST',
      body: params
    })
  },
  updateComDepTeamsList(params) {
    return request(`users/companies/${params.company_id}/departments/${params.department_id}/teams/${params.id}`, {
      method: 'PATCH',
      body: params
    })
  },
  deleteComDepTeamsList(params) {
    return request(`users/companies/${params.company_id}/departments/${params.department_id}/teams/${params.id}`, {
      method: 'DELETE',
      body: params
    })
  },
  //判断上述名称是否存在
  getExistsFlag(params) {
    return request(`users/exists?${stringify(params)}`, {
      method: 'GET'
    })
  },

  // 人员列表api
  getPersonList(params) {
    return request(`users/?${stringify(params)}`, {
      method: 'GET',
    });
  },
  getPersonNameList() {
    return request(`users/name/`, {
      method: 'GET',
    });
  },

  // 创建人员api
  createPerson(params) {
    return request('users/', {
      method: 'POST',
      body: params,
    });
  },
  // 删除人员api
  deletePerson(params) {
    return request(`users/${params.id}/`, {
      method: 'PATCH',
      body: params,
    });
  },
  // 更新人员api
  updatePerson(params) {
    return request(`users/${params.id}/`, {
      method: 'PATCH',
      body: params,
    });
  },

  // 应用列表api
  getAppList(params) {
    return request(`users/app/?${stringify(params)}`, {
      method: 'GET',
    });
  },
  // 创建应用api
  createApp(params) {
    return request('users/app/', {
      method: 'POST',
      body: params,
    });
  },
  // 删除应用api
  deleteApp(id) {
    return request(`users/app/${id}/`, {
      method: 'DELETE',
    });
  },
  // 更新重置应用api
  updateApp(params) {
    return request(`users/app/${params.id}/`, {
      method: 'PATCH',
      body: params,
    });
  },
  // 下载应用api
  downloadApp(id) {
    return request(`users/app/download/${id}/`, {
      method: 'GET',
    });
  },
  // 下载历史记录
  downHistoryFile(params) {
    return request(`devices/${params.deviceId}/controllers/${params.id}/files`, {
      method: 'GET',
    });
  },

  // 客户列表api
  getCustomerList(params) {
    return request(`order/client/?${stringify(params)}`, {
      method: 'GET',
    });
  },
  // 创建客户api
  createClient(params) {
    return request('order/client/', {
      method: 'POST',
      body: params,
    });
  },
  // 删除客户api
  deleteClient(id) {
    return request(`order/client/${id}/`, {
      method: 'DELETE',
    });
  },
  // 更新客户api
  updateClient(params) {
    return request(`order/client/${params.id}/`, {
      method: 'PATCH',
      body: params,
    });
  },

  //刀具 knifes
  //刀具列表
  getKnifesList(params) {
    return request(`knifes/?${stringify(params)}`, {
      method: 'GET',
    })
  },
  createKnifes(params) {
    return request(`knifes/`, {
      method: 'POST',
      body: params,
    })
  },
  updateKnifes(params) {
    return request(`knifes/${params.id}/`, {
      method: 'PATCH',
      body: params,
    })
  },
  deleteKnifes(id) {
    return request(`knifes/${id}`, {
      method: 'DELETE',
    })
  },
  checkKnifes(params) {
    return request(`knifes/check`, {
      method: 'POST',
      body: params,
    })
  },

  //刀具类型列表
  getKnifesTypeList(params) {
    return request(`knifes/types/?${stringify(params)}`, {
      method: 'GET',
    })
  },
  //刀具类型增删改
  createKnifesType(params) {
    return request('knifes/types/', {
      method: 'POST',
      body: params,
    })
  },
  deleteKnifesType(id) {
    return request(`knifes/types/${id}`, {
      method: 'DELETE',
    })
  },
  updateKnifesType(params) {
    return request(`knifes/types/${params.id}/`, {
      method: 'PATCH',
      body: params,
    });
  },
  checkKnifesType(params) {
    return request(`knifes/types/check`, {
      method: 'POST',
      body: params,
    })
  },
  //切削材质列表
  getKnifesMaterialsList(params) {
    return request(`knifes/materials/?${stringify(params)}`, {
      method: 'GET',
    })
  },
  //刀具类型增删改
  createKnifesMaterials(params) {
    return request('knifes/materials/', {
      method: 'POST',
      body: params,
    })
  },
  deleteKnifesMaterials(id) {
    return request(`knifes/materials/${id}`, {
      method: 'DELETE',
    })
  },
  updateKnifesMaterials(params) {
    return request(`knifes/materials/${params.id}/`, {
      method: 'PATCH',
      body: params,
    });
  },
  checkKnifesMaterials(params) {
    return request('knifes/materials/check', {
      method: 'POST',
      body: params,
    })
  },

  // 设备 Device
  // 设备类型列表api
  getDeviceTypeList() {
    return request('devices/category/', {
      method: 'GET',
    });
  },
  // 设备分类增删改
  createDeviceType(params) {
    return request('devices/category/', {
      method: 'POST',
      body: params,
    });
  },
  deleteDeviceType(id) {
    return request(`devices/category/${id}/`, {
      method: 'DELETE',
    });
  },
  updateDeviceType(params) {
    return request(`devices/category/${params.id}/`, {
      method: 'PATCH',
      body: params,
    });
  },

  // 设备分组列表api
  getDeviceGroupsList(params) {
    return request(`devices/groups/?${stringify(params)}`, {
      method: 'GET',
    });
  },
  // 设备分组增删改
  createDeviceGroups(params) {
    return request('devices/groups/', {
      method: 'POST',
      body: params,
    });
  },
  deleteDeviceGroups(id) {
    return request(`devices/groups/${id}`, {
      method: 'DELETE',
    });
  },
  updateDeviceGroups(params) {
    return request(`devices/groups/${params.id}`, {
      method: 'PATCH',
      body: params,
    });
  },

  //设备上下线
  changeDeviceStatus(params) {
    return request(`units/status/`, {
      method: 'PATCH',
      body: params,
    });
  },

  //设备控制器列表
  getDevicesControllersList(id) {
    return request(`devices/${id}/controllers/`, {
      method: 'GET',
    })
  },
  createDeviceControllers(params) {
    return request(`devices/${params.id}/controllers/`, {
      method: 'POST',
      body: params,
    });
  },
  deleteDeviceControllers(params) {
    return request(`devices/${params.id}/controllers/${params.controller_id}`, {
      method: 'DELETE',
    });
  },
  updateDeviceControllers(params) {
    return request(`devices/${params.id}/controllers/${params.controller_id}`, {
      method: 'PATCH',
      body: params,
    });
  },
  //控制器通道列表
  getControllersChannelsList(params) {
    return request(`devices/${params.id}/controllers/${params.controller_id}/channels`, {
      method: 'GET',
    })
  },
  createControllersChannels(params) {
    return request(`devices/${params.device_id}/controllers/${params.controller_id}/channels/`, {
      method: 'POST',
      body: params,
    });
  },
  deleteControllersChannels(params) {
    return request(`devices/${params.device_id}/controllers/${params.controller_id}/channels/${params.id}`, {
      method: 'DELETE',
      body: params,
    });
  },
  updateControllersChannels(params) {
    return request(`devices/${params.device_id}/controllers/${params.controller_id}/channels/${params.id}`, {
      method: 'PATCH',
      body: params,
    });
  },

  // 设备最大数量接口
  getDeviceMaxCount() {
    return request(`devices/max_count/`, {
      method: 'GET',
    });
  },

  // 可用的生产单元列表api（订单任务）
  getUnitsUnUsedList() {
    return request(`units/?search=assignable`, {
      method: 'GET',
    });
  },

  // 设备状态统计列表api（系统+大屏）
  getDeviceStatusCount() {
    return request(`devices/real_time_status_count`, {
      method: 'GET',
    });
  },
  // 设备状态分页列表api（大屏）
  getDeviceStatusList() {
    return request(`devices/real_time_status`, {
      method: 'GET',
    });
  },
  // 设备分页列表api（系统）
  getDeviceList(params) {
    return request(`devices/?${stringify(params)}`, {
      method: 'GET',
    });
  },
  //全部设备列表（含设备模型）
  getAllDeviceModels() {
    return request('devices/', {
      method: 'GET',
    });
  },
  //设备模型数量统计
  getDeviceModelsList() {
    return request('devices/statistics/history/counting/models', {
      method: 'GET',
    })
  },
  // 所有设备列表（产线设备）
  getDeviceNameList() {
    return request('devices/name/', {
      method: 'GET',
    });
  },
  // 可用设备列表（产线设备）
  getShopFreeDevices(params) {
    return request(`devices/name/?${stringify(params)}`, {
      method: 'GET',
    });
  },

  // 获取所有车间和对应车间的游离设备
  getWorkShopDevice(params) {
    return request(`devices/?${stringify(params)}`, {
      method: 'GET',
    });
  },

  // 创建设备api
  createDevice(params) {
    return request('devices/', {
      method: 'POST',
      body: params,
    });
  },
  // 删除设备api
  deleteDevice(params) {
    if (params.deleted === true) {
      // 真删除,DELETE
      return request(`devices/${params.id}`, {
        method: 'DELETE',
      });
    } else {
      return request(`devices/${params.id}/`, {
        method: 'PATCH',
        body: {
          deleted: false,
        },
      });
    }
  },
  // 更新设备api
  updateDevice(params) {
    return request(`devices/${params.id}`, {
      method: 'PATCH',
      body: params,
    });
  },

  // 单机设备详情数据api
  getDeviceDetail(id) {
    return request(`devices/${id}`, {
      method: 'GET',
    });
  },

  // 单机设备任务状态api
  getDeviceStatus(id) {
    return request(`devices/${id}/status/`, {
      method: 'GET',
    });
  },

  // 单机设备历史累计时间api
  getDeviceTotalTime(id) {
    return request(`devices/${id}/accumulation/time/`, {
      method: 'GET',
    });
  },


  // 单个控制器的数据
  // 时间利用率 TimeRate
  getCTLTimeRate(params) {
    return request(`devices/${params.deviceId}/controllers/${params.id}/time_utilization`, {
      method: 'GET',
    });
  },
  // 运行时间分布 Distribute
  getCTLDistribute(params) {
    return request(`devices/${params.deviceId}/controllers/${params.id}/time_scatter`, {
      method: 'GET',
    });
  },
  // 控制器系统信息 SystemInfo
  getCTLSystemInfo(params) {
    return request(`devices/${params.deviceId}/controllers/${params.id}/status/`, {
      method: 'GET',
    });
  },
  // 控制器自定义数据 CustomData
  getCTLCustomData(params) {
    return request(`devices/${params.deviceId}/controllers/${params.id}/customs/`, {
      method: 'GET',
    });
  },
  // 控制器实时状态 TimeStatus
  getCTLTimeStatus(params) {
    return request(`devices/${params.deviceId}/controllers/${params.id}/real_time/status/`, {
      method: 'GET',
    });
  },

  // 产线 ProductLIne
  // 产线列表api
  getProductLIneList(params) {
    return request(`product_line/?${stringify(params)}`, {
      method: 'GET',
    });
  },
  getProductLIneInfo(id) {
    return request(`product_line/${id}`, {
      method: 'GET',
    });
  },
  // 创建产线api
  createProductLIne(params) {
    return request('product_line/', {
      method: 'POST',
      body: params,
    });
  },
  // 删除产线api
  deleteProductLIne(id) {
    return request(`product_line/${id}`, {
      method: 'DELETE',
    });
  },
  // 修改产线api
  updateProductLIne(params) {
    return request(`product_line/${params.id}`, {
      method: 'PATCH',
      body: params,
    });
  },
  //产线设备列表
  getProductLIneDevicesList(id) {
    return request(`product_line/${id}/devices/`, {
      method: 'GET',
    });
  },
  //创建产线设备
  createProductLIneDevices(params) {
    return request(`product_line/${params.id}/devices/`, {
      method: 'POST',
      body: params
    });
  },
  //产线计数模型列表
  getProductLIneCountingModeList(params) {
    return request(`product_line/${params.id}/counting_mode/?${stringify(params)}`, {
      method: 'GET',
    });
  },
  //创建产线计数模型
  createProductLIneCountingModeList(params) {
    return request(`product_line/${params.id}/counting_mode/`, {
      method: 'POST',
      body: params
    });
  },
  //更新产线计数模型状态
  updateProductLIneCountingModeList(params) {
    return request(`product_line/${params.pl_id}/counting_mode/${params.id}`, {
      method: 'PATCH',
      body: params.data
    });
  },
  //删除产线计数模型
  deleteProductLIneCountingModeList(params) {
    return request(`product_line/${params.pl_id}/counting_mode/${params.id}`, {
      method: 'DELETE',
    });
  },
  //设备计数模型列表
  getDeviceCountingModeList(id) {
    return request(`devices/${id}/counting_mode/`, {
      method: 'GET',
    });
  },
  //创建设备计数模型
  createDeviceCountingModeList(params) {
    return request(`devices/${params.id}/counting_mode/`, {
      method: 'POST',
      body: params.data
    });
  },
  //更新设备计数模型状态
  updateDeviceCountingModeList(params) {
    return request(`devices/${params.device_id}/counting_mode/${params.id}`, {
      method: 'PATCH',
      body: params.data
    });
  },
  //删除设备计数模型状态
  deleteDeviceCountingModeList(params) {
    return request(`devices/${params.device_id}/counting_mode/${params.id}`, {
      method: 'DELETE',
    });
  },

  // 产品 Productions
  // 产品列表api
  getProductionNameList() {
    return request(`productions/name/?status=USING`, {
      method: 'GET',
    });
  },
  getProductionList(params) {
    return request(`productions/?${stringify(params)}`, {
      method: 'GET',
    });
  },
  // 创建产品api
  createProduction(params) {
    return request('productions/', {
      method: 'POST',
      body: params,
    });
  },
  // 删除产品api
  deleteProduction(id) {
    return request(`productions/${id}/`, {
      method: 'DELETE',
    });
  },
  // 修改产品api
  updateProduction(params) {
    return request(`productions/${params.id}/`, {
      method: 'PATCH',
      body: params,
    });
  },
  // 查看产品api
  detailProduction(id) {
    return request(`productions/${id}/`, {
      method: 'GET',
    });
  },
  // 产品累计数据
  totalProduction(id) {
    return request(`productions/${id}/task/accumulations`, {
      method: 'GET',
    });
  },
  // 产品日志列表
  getDailyProductList(params) {
    return request(`productions/${params.id}/production_log?page=${params.page}&size=${params.size}`, {
      method: 'GET',
    });
  },
  // 获取测量要求列表
  getMeasurementRequirement(params) {
    return request(`productions/${params?.product_id}/measures/`, {
      method: 'GET',
    });
  },
  // 获取零件图号测量要求列表
  getModMeasurementRequirement(params) {
    return request(`component/${params?.component_id}/measures/`, {
      method: 'GET',
    });
  },
  //创建测量要求
  createMeasurementRequirement(params) {
    return request(`productions/${params?.product_id}/measures/`, {
      method: 'POST',
      body: params,
    });
  },
  //创建零件图号测量要求
  createModMeasurementRequirement(params) {
    return request(`component/${params?.component_id}/measures/`, {
      method: 'POST',
      body: params,
    });
  },
  //修改测量要求
  updateMeasurementRequirement(params) {
    return request(`productions/${params?.product_id}/measures/${params?.id}`, {
      method: 'PATCH',
      body: params,
    });
  },
  //修改零件图号测量要求
  updateModMeasurementRequirement(params) {
    return request(`component/${params?.component_id}/measures/${params?.id}`, {
      method: 'PATCH',
      body: params,
    });
  },
  // 删除测量要求
  deleteMeasurementRequirement(params) {
    return request(`productions/${params?.product_id}/measures/${params?.id}`, {
      method: 'DELETE',
    });
  },
  // 删除零件图号测量要求
  deleteModMeasurementRequirement(params) {
    return request(`component/${params?.component_id}/measures/${params?.id}`, {
      method: 'DELETE',
    });
  },
  // 产品图号api
  getComponentList(id) {
    return request(`productions/${id}/components/custom`, {
      method: 'GET',
    });
  },
  // 创建产品图号api
  createComponent(params) {
    return request(`productions/${params.product_belong}/components/custom`, {
      method: 'POST',
      body: params,
    });
  },
  // 修改产品图号api
  updateComponent(params) {
    return request(`productions/${params.product_belong}/components/${params.id}/custom`, {
      method: 'PATCH',
      body: params,
    });
  },
  //删除产品图纸
  deleteUplodeFile(id) {
    return request(`files/component_image/${id}/`, {
      method: 'DELETE',
    });
  },

  //获取产品不良原因列表
  getRejectReasonList(params) {
    return request(`users/production_work/rejects_count/reason/?${stringify(params)}`, {
      method: 'GET',
    });
  },
  //创建产品不良原因
  creaetRejectReason(params) {
    return request("users/production_work/rejects_count/reason/", {
      method: 'POST',
      body: params,
    })
  },
  //修改产品不良原因
  updateRejectReason(params) {
    console.log(params)
    return request(`users/production_work/rejects_count/reason/${params.reason_id}`, {
      method: 'PATCH',
      body: params,
    })
  },
  //删除产品不良原因
  deleteRejectReason(reason_id) {
    return request(`users/production_work/rejects_count/reason/${reason_id}`, {
      method: 'DELETE',
    })
  },
  //检查产品不良原因是否存在
  checkRejectReason(params) {
    return request(`users/production_work/rejects_count/reason/check`, {
      method: 'POST',
      body: params,
    })
  },

  //刀具清单
  //获取刀具清单
  getToolList(params) {
    return request(`component/${params.component_id}/knifes/?${stringify(params)}`, {
      method: 'GET',
    })
  },
  createToolList(params) {
    return request(`component/${params.component_id}/knifes/`, {
      method: 'POST',
      body: params,
    })
  },
  updateToolList(params) {
    return request(`component/${params.component_id}/knifes/${params.uid}/`, {
      method: 'PATCH',
      body: params,
    })
  },
  deleteToolList(params) {
    return request(`component/${params.component_id}/knifes/${params.uid}`, {
      method: 'DELETE',
    })
  },

  // 文件 File
  // 下载文件材料api
  downloadFile(params) {
    return request(`files/program/?${stringify(params)}`, {
      method: 'GET',
    });
  },
  // 删除文件材料api
  deleteFile(params) {
    return request(`files/component_file/${params.uid}/`, {
      method: 'DELETE',
    });
  },
  deleteProcess(params) {
    return request(`productions/${params.product_belong}/components/${params.cid}/process/${params.pid}`, {
      method: 'DELETE',
    });
  },

  // 查询产品所有分类列表api
  getProductTypesList(params) {
    return request(`productions/s_category_type?${stringify(params)}`, {
      method: 'GET',
    });
  },
  // 查询产品一级分类列表api
  getCategoryList() {
    return request('productions/category/', {
      method: 'GET',
    });
  },
  // 查询产品二级分类列表api
  getSCategoryList(params) {
    return request(`productions/category/?${stringify(params)}`, {
      method: 'GET',
    });
  },
  // 设备分类增删改
  createProductType(params) {
    return request('productions/category/', {
      method: 'POST',
      body: params,
    });
  },
  deleteProductType(params) {
    return request(`productions/category/${params.id}/`, {
      method: 'DELETE',
    });
  },
  updateProductType(params) {
    return request(`productions/category/${params.id}/`, {
      method: 'PATCH',
      body: params,
    });
  },

  // 车间 WorkShop
  // 车间列表api
  getWorkShopList(params) {
    return request(`workshop/?${stringify(params)}`, {
      method: 'GET',
    });
  },
  // 创建车间api
  createWorkShop(params) {
    return request('workshop/', {
      method: 'POST',
      body: params,
    });
  },
  // 删除车间api
  deleteWorkShop(id) {
    return request(`workshop/${id}/`, {
      method: 'DELETE',
    });
  },
  // 修改车间api
  updateWorkShop(params) {
    return request(`workshop/${params.id}/`, {
      method: 'PUT',
      body: params,
    });
  },

  // 创建网关设备api
  createNetDevice(params) {
    return request('devices/simple/', {
      method: 'POST',
      body: params,
    });
  },
  // 获取网关设备列表api
  getNetDeviceList(params) {
    return request(`bridges/info/${params.bridge}/devices?page=${params.page}&size=${params.size}&search=${params.search}`, {
      method: 'GET',
    });
  },
  // 网关实时状态 api
  getNetStatusList() {
    return request('bridges/realtime/status', {
      method: 'GET',
    });
  },
  // 网关列表 api
  getNetTypeList(params) {
    return request(`bridges/?search=${params.search}`, {
      method: 'GET',
    });
  },
  // 网关详情 api
  getNetTypeInfo(params) {
    return request(`bridges/info/${params.bridge}/`, {
      method: 'GET',
    });
  },
  // 网关生效 api
  effectNet(params) {
    return request(`bridge/config/?${stringify(params)}`, {
      method: 'GET',
    });
  },

  // 检查网关生效 api
  checkEffectNet(params) {
    return request(`bridge/config/check/?${stringify(params)}`, {
      method: 'GET',
    });
  },

  // 制造商名称重复验证
  VendorNameTest(params) {
    return request(`devices/check`, {
      method: 'POST',
      body: params,
    });
  },

  // 大屏名称重复验证
  ScreenNameTest(params) {
    return request(`screens/check`, {
      method: 'POST',
      body: params,
    });
  },

  // 客户姓名重复验证
  ClientNameTest(params) {
    return request(`order/inspect`, {
      method: 'POST',
      body: params,
    });
  },

  // 应用账户名称重复验证
  AppNameTest(params) {
    return request(`users/exists?${stringify(params)}`, {
      method: 'GET',
    });
  },

  // 人员员工姓名,工号,手机号重复验证
  UserCheck(params) {
    return request(`users/exists?${stringify(params)}`, {
      method: 'GET',
    });
  },

  // 车间名称重复验证
  WSNameTest(params) {
    return request(`workshop/check`, {
      method: 'POST',
      body: params,
    });
  },

  // 产线名称重复验证
  PLNameTest(params) {
    return request(`product_line/check`, {
      method: 'POST',
      body: params,
    });
  },

  // 设备编号,设备类型，产品分类重复验证
  DevNumTest(params) {
    return request(`devices/check/`, {
      method: 'POST',
      body: params,
    });
  },
  DevTypeNameTest(params) {
    return request(`devices/category/check`, {
      method: 'POST',
      body: params,
    });
  },
  ProTypeNameTest(params) {
    return request(`productions/category/check`, {
      method: 'POST',
      body: params,
    });
  },

  // 产品图号和产品名称重复验证
  ProNumTest(params) {
    return request(`component/check`, {
      method: 'POST',
      body: params,
    });
  },
  ProNameTest(params) {
    return request(`productions/check`, {
      method: 'POST',
      body: params,
    });
  },

  // 查重导入导出人员、订单、产品、客户
  handleCheck(params) {
    return request(`files/check`, {
      method: 'POST',
      body: params,
    });
  },
  handleImport(params) {
    return request(`files/upload`, {
      method: 'POST',
      body: params,
    });
  },
  handleExport(params) {
    return request(`files/export/${params.type}`, {
      method: 'GET',
    });
  },
  // 导出控制器
  handleExportCNC() {
    return request(`files/export/controller`, {
      method: 'GET',
    });
  },

  // 数据大屏增删改查
  getScreenList() {
    return request(`screens/`, {
      method: 'GET',
    });
  },
  createScreen(params) {
    return request('screens/', {
      method: 'POST',
      body: params,
    });
  },
  updateScreen(params) {
    return request(`screens/${params.id}`, {
      method: 'PATCH',
      body: params,
    });
  },
  deleteScreen(id) {
    return request(`screens/${id}`, {
      method: 'DELETE',
    });
  },
  getScreenProcessInfo(params) {
    return request(`units/${params.id}/statistics/by_day_process_info/`, {
      method: 'GET',
    });
  },

  // 所有企业列表(服务商+制造商+企业)
  getEnterpriseList(params) {
    return request(`admin/enterprises/?${stringify(params)}`, {
      method: 'GET',
      noUseEnterPriseID: true,
    });
  },
  // 创建企业api
  createEnterprise(params) {
    return request('admin/enterprises/', {
      method: 'POST',
      body: params,
      noUseEnterPriseID: true,
    });
  },
  // 删除企业api
  deleteEnterprise(id) {
    return request(`admin/enterprises/${id}`, {
      method: 'DELETE',
      noUseEnterPriseID: true,
    });
  },
  // 更新企业api
  updateEnterprise(params) {
    return request(`admin/enterprises/${params.id}`, {
      method: 'PATCH',
      body: params,
      noUseEnterPriseID: true,
    });
  },
  // 企业名称重复验证
  enterpriseCheck(params) {
    return request(`admin/enterprises/exists?${stringify(params)}`, {
      method: 'GET',
      noUseEnterPriseID: true,
    });
  },

  // 所有账户列表(服务商+制造商+企业)
  getAccountList(params) {
    return request(`admin/users/?${stringify(params)}`, {
      method: 'GET',
      noUseEnterPriseID: true,
    });
  },
  getAccountData(id) {
    return request(`admin/users/${id}`, {
      method: 'GET',
      noUseEnterPriseID: true,
    });
  },
  // 创建账户api
  createAccount(params) {
    return request('admin/users/', {
      method: 'POST',
      body: params,
      noUseEnterPriseID: true,
    });
  },
  // 删除账户api
  deleteAccount(id) {
    return request(`admin/users/${id}`, {
      method: 'DELETE',
      noUseEnterPriseID: true,
    });
  },
  // 更新账户api
  updateAccount(params) {
    return request(`admin/users/${params.id}`, {
      method: 'PATCH',
      body: params,
      noUseEnterPriseID: true,
    });
  },
  // 账户名称重复验证
  accountCheck(params) {
    return request(`admin/users/exists?${stringify(params)}`, {
      method: 'GET',
      noUseEnterPriseID: true,
    });
  },

  //获取班次列表信息
  getShiftsList(params) {
    return request(`shifts/?${stringify(params)}`, {
      method: 'GET',
    });
  },
  // 创建班次列表信息
  createShifts(params) {
    return request('shifts/', {
      method: 'POST',
      body: params,
    });
  },
  // 删除班次列表信息
  deleteShifts(id) {
    return request(`shifts/${id}`, {
      method: 'DELETE',
    });
  },
  // 更新班次列表信息
  updateShifts(params) {
    return request(`shifts/${params.id}`, {
      method: 'PATCH',
      body: params,
    });
  },

  // 制造商接口
  // 获取所有的制造商列表
  getManufacturerList(params) {
    return request(`enterprises/?${stringify(params)}`, {
      method: 'GET',
      noUseEnterPriseID: true,
    });
  },
  // 制造商获取当前设备分布的企业
  getEnterpriseBelongList() {
    return request('affiliation', {
      method: 'GET',
    });
  },
  // 制造商所选企业设备统计
  getCompanyDeviceCount(params) {
    if (params.id === 0) {
      // 所有企业
      return request('company/real_time_status_count', {
        method: 'GET',
      });
    } else {
      // 所选企业
      return request(`company/${params.id}/real_time_status_count`, {
        method: 'GET',
      });
    }
  },
  // 制造商所选企业设备趋势
  getCompanyDeviceStat(params) {
    if (params.id === 0) {
      // 所有企业
      return request(`company/device_status_ratio?${changeDate(params)}&status=${params.status}`, {
        method: 'GET',
      });
    } else {
      // 所选企业
      return request(
        `company/${params.id}/device_status_ratio?${changeDate(params)}&status=${params.status}`,
        {
          method: 'GET',
        }
      );
    }
  },
  // 制造商所选企业设备时间利⽤率
  getCompanyDeviceRatio(params) {
    if (params.id === 0) {
      // 所有企业
      return request(`company/device_of_utilization?${changeDate(params)}`, {
        method: 'GET',
      });
    } else {
      // 所选企业
      return request(`company/${params.id}/device_of_utilization?${changeDate(params)}`, {
        method: 'GET',
      });
    }
  },
  // 制造商所选企业长期离线设备
  getCompanyDeviceOffline(params) {
    if (params.id === 0) {
      // 所有企业
      return request('company/device_long_time_offline', {
        method: 'GET',
      });
    } else {
      // 所选企业
      return request(`company/${params.id}/device_long_time_offline`, {
        method: 'GET',
      });
    }
  },
  // 重点维护严重报警设备列表（实时）
  getRealTimeSeriousAlarms() {
    return request('company/real_time_alarms', {
      method: 'GET',
    });
  },

  // 制造商所选企业设备故障报警统计
  getCompanyDeviceDefault(params) {
    if (params.id === 0) {
      // 所有企业
      return request('company/real_time_malfunctions', {
        method: 'GET',
      });
    } else {
      // 所选企业
      return request(`company/${params.id}/real_time_malfunctions`, {
        method: 'GET',
      });
    }
  },
  // 制造商所选企业设备报警类型、次数统计
  getCompanyAlarmData(params) {
    if (params.id === 0) {
      // 所有企业
      return request(`company/alarm_number?${changeDate(params)}`, {
        method: 'GET',
      });
    } else {
      // 所选企业
      return request(`company/${params.id}/alarm_number?${changeDate(params)}`, {
        method: 'GET',
      });
    }
  },
  // 制造商所选企业设备故障报警四个列表
  getCompanyDefaultTabOne(params) {
    if (params.id === 0) {
      // 所有企业
      return request(`company/device_alarm_accumulation_number?${changeDate(params)}`, {
        method: 'GET',
      });
    } else {
      // 所选企业
      return request(
        `company/${params.id}/device_alarm_accumulation_number?${changeDate(params)}`,
        {
          method: 'GET',
        }
      );
    }
  },
  getCompanyDefaultTabTwo(params) {
    if (params.id === 0) {
      // 所有企业
      return request(`company/device_accumulation_time/?${changeDate(params)}`, {
        method: 'GET',
      });
    } else {
      // 所选企业
      return request(`company/${params.id}/device_accumulation_time/?${changeDate(params)}`, {
        method: 'GET',
      });
    }
  },
  getCompanyDefaultTabThr(params) {
    if (params.id === 0) {
      // 所有企业
      return request(`company/fault_statistics?${changeDate(params)}`, {
        method: 'GET',
      });
    } else {
      // 所选企业
      return request(`company/${params.id}/fault_statistics?${changeDate(params)}`, {
        method: 'GET',
      });
    }
  },
  getCompanyDefaultTabFour(params) {
    if (params.id === 0) {
      // 所有企业
      return request(`company/important_device_event?${changeDate(params)}`, {
        method: 'GET',
      });
    } else {
      // 所选企业
      return request(`company/${params.id}/important_device_event?${changeDate(params)}`, {
        method: 'GET',
      });
    }
  },

  // 地图json数据
  getGeoJsonData(params) {
    // 默认查询为code可选参数
    if (params.code == 100000 || params.code % 10 == 0) {
      // 省市区
      return fetch(`https://geo.datav.aliyun.com/areas/bound/geojson?code=${params.code}_full`, {
        method: 'GET',
        referrer: '',
      })
        .then(res => {
          return res.json();
        })
        .then(data => {
          return data;
        });
    } else {
      // 县乡镇
      return fetch(`https://geo.datav.aliyun.com/areas/bound/geojson?code=${params.code}`, {
        method: 'GET',
        referrer: '',
      })
        .then(res => {
          return res.json();
        })
        .then(data => {
          return data;
        });
    }
  },

  // 制造商设备区域数量分布
  getDeviceDisCount(params) {
    // 默认查询为code可选参数
    return request(`devices/distribution?${stringify(params)}`, {
      method: 'GET',
    });
  },
  // 制造商设备类型数量占比
  getDeviceNumberRatio() {
    return request(`devices/model_number_ratio`, {
      method: 'GET',
    });
  },

  // 获取远程请求列表
  getRemoteConnectList(params) {
    return request(`remotes/?${stringify(params)}`, {
      method: 'GET',
    });
  },
  // 异步获取远程请求记录
  getRemoteConnectLog(params) {
    return request(`remotes/history?${stringify(params)}`, {
      method: 'GET',
    });
  },
  // 异步提交远程请求操作
  postRemoteConnectData(params) {
    return request(`remotes/history`, {
      method: 'POST',
      body: params,
    });
  },
};
