import img403 from '@/assets/403.svg';
import img404 from '@/assets/404.svg';
import img500 from '@/assets/500.svg';

const config = {
  403: {
    img: img403,
    title: '403',
    desc: '抱歉，你无权访问该页面，请联系管理员',
  },
  404: {
    img: img404,
    title: '404',
    desc: '抱歉，尚无企业信息，请联系相关企业',
  },
  500: {
    img: img500,
    title: '500',
    desc: '抱歉，服务器出错了',
  },
};

export default config;
