import { stringify } from 'qs'; // 方法引入
import request from '../utils/request'; // 引入ajax请求方法
import moment from 'moment';

export default {
  // TimelineChart图表数据 api
  getChartList(params) {
    switch (params.type) {
      case 'today':
        const date = new Date();
        if(!params.id || params.id === 0){ // 所有企业
          return request(`company/boot-run/oneDay=${moment(date).format('YYYY-MM-DD')}`, {
            method: 'GET',
          });
        }else{ // 所选企业
          return request(`company/${params.id}/boot-run/oneDay=${moment(date).format('YYYY-MM-DD')}`, {
            method: 'GET',
          });
        }
      case 'week':
        return request(`company/boot-run/?days=7`, {
          method: 'GET',
        });
      case 'month':
        return request(`company/boot-run/?days=30`, {
          method: 'GET',
        });
      case 'year':
        return request(`company/boot-run/?days=90`, {
          method: 'GET',
        });
      case 'oneDay':
        if(!params.id || params.id === 0){ // 所有企业
          return request(`company/boot-run/?oneDay=${params.time}`, {
            method: 'GET',
          });
        }else{ // 所选企业
          return request(`company/${params.id}/boot-run/?oneDay=${params.time}`, {
            method: 'GET',
          });
        }
      default: break;
    }
  },
  getChartListToday(){
    return request(`company/boot-run/`, {
      method: 'GET',
    });
  },
  getChartListMonth(){
    return request(`company/boot-run/?days=30`, {
      method: 'GET',
    });
  },
  getChartListYear(){
    return request(`company/boot-run/?days=90`, {
      method: 'GET',
    });
  },

  // 故障率统计数据 api
  getFaultList() {
    return request('devices/statistics/status/', {
      method: 'GET',
    });
  },

  // 订单任务数据 api
  getOrderTask() {
    return request('order/task/status/', {
      method: 'GET',
    });
  },

  // 故障详情列表数据 api
  getFaultDetailList(params) {
    return request(`company/event/?${stringify(params)}`, {
      method: 'GET',
    });
  },
  // 故障频率列表数据 api
  getFaultRateList(params) {
    return request(`company/error-rate/?${stringify(params)}`, {
      method: 'GET',
    });
  },

  // 累计产量数据api - 大屏
  getTotalProduction() {
    return request('company/total_production/', {
      method: 'GET',
    });
  },
  // 今日实时产量数据api - 大屏
  getRealTimeProduction(params) {
    return request(`company/real_time_production/?${stringify(params)}`, {
      method: 'GET',
    });
  },
  // 今日产量数据api - 大屏
  getRealTimeTotalProduction(){
    return request(`company/real_time_total_production/`, {
      method: 'GET',
    });
  },
  // 历史产量数据api - 大屏
  getHistoricalProduction() {
    return request('company/historical_production/', {
      method: 'GET',
    });
  },
  // 即时报警信息api - 大屏
  getCurrentEvent() {
    return request('company/current_event/', {
      method: 'GET',
    });
  },
  // 当日报警信息api - 大屏
  getTodayEvent() {
    return request('company/current_event/?time=today', {
      method: 'GET',
    });
  },
  // 故障类型占比api - 大屏
  getEventRate(params) {
    return request(`company/alarms_stat/?${stringify(params)}`, {
      method: 'GET',
    });
  },

  // 1.产线目标件数和完成件数数据 api
  getTotalTarget(params) {
    return request(`company/finish-goal/?${stringify(params)}`, {
      method: 'GET',
    });
  },
  // 产线实时产量数据 api
  getDataOnTime(id) {
    return request(`product_line/${id}/real_time_production/`, {
      method: 'GET',
    });
  },
  // 产线我的任务数据 api
  getProLineTask(id) {
    return request(`product_line/${id}/task/`, {
      method: 'GET',
    });
  },
  // 产线任务完成数据 api
  getProLineDone(id) {
    return request(`product_line/${id}/task/?status=FINISHED`, {
      method: 'GET',
    });
  },
  // 2.产线我的效率数据-时间利用率 api
  getProLineRate(id) {
    return request(`product_line/${id}/time_utilization/`, {
      method: 'GET',
    });
  },
  // 产线我的效率数据-工序时间 api
  getProLineOP(id) {
    return request(`product_line/${id}/op/`, {
      method: 'GET',
    });
  },
  // 3.产线设备列表数据 api
  getProLineDev(id) {
    return request(`product_line/${id}/devices/`, {
      method: 'GET',
    });
  },
  // 产线设备分类列表数据 api
  getProLineDevType(id) {
    return request(`product_line/${id}/devices/types/`, {
      method: 'GET',
    });
  },

  // 单机设备列表数据 api
  getUnitDevice() {
    return request('devices/type/', {
      method: 'GET',
    });
  },
  // 设备目标件数和完成件数数据 api
  getDeviceGoal(id) {
    return request(`devices/${id}/finish_goal/`, {
      method: 'GET',
    });
  },
  // 单机设备实时产量数据 api
  getDevDataOnTime(id) {
    return request(`devices/${id}/real_time_production/`, {
      method: 'GET',
    });
  },
  // 单机设备时间利用率 api
  getDeviceTimeRate(id) {
    return request(`devices/${id}/time_utilization/`, {
      method: 'GET',
    });
  },
  // 单机设备运行时间分布 api
  getDeviceDistribute(id) {
    return request(`devices/${id}/time_scatter/`, {
      method: 'GET',
    });
  },
  // 1.单机设备我的任务数据 api
  getDeviceTask(id) {
    return request(`devices/${id}/tasks/`, {
      method: 'GET',
    });
  },
  // 2.单机设备任务完成数据 api
  getDeviceDone(params) {
    return request(`devices/${params.id}/tasks/?status=1&${params.type}=7`, {
      method: 'GET',
    });
  },
  // 单机设备历史产量数据 api
  getDeviceHistory(params) {
    switch (params.type) {
      case 'week':
        return request(`devices/${params.id}/historical_production/?days=7`, {
          method: 'GET',
        });
      case 'month':
        return request(`devices/${params.id}/historical_production/?days=30`, {
          method: 'GET',
        });
      case 'year':
        return request(`devices/${params.id}/historical_production/?days=90`, {
          method: 'GET',
        });
      case 'dates':
        if (params.types === 'hour') {
          return request(
            `devices/${params.id}/historical_production/?timeinter=${params.dateRange[0]}~${
              params.dateRange[1]
            }&interval=60`,
            {
              method: 'GET',
            }
          );
        }
        if (params.types === 'day') {
          return request(
            `devices/${params.id}/historical_production/?timeinter=${params.dateRange[0]}~${
              params.dateRange[1]
            }&interval=1440`,
            {
              method: 'GET',
            }
          );
        }
      default: break;
    }
  },

  // 3.1 单机设备日志表格数据 api
  getDeviceLog(params) {
    return request(`devices/${params.id}/log/?page=${params.page}&size=${params.size}`, {
      method: 'GET',
    });
  },
  // 3.15 单机设备运行状态数据 api
  getDeviceStatusChart(params) {
    switch (params.type) {
      case 'today':
        return request(`devices/${params.id}/time_status_variety/`, {
          method: 'GET',
        });
      case 'week':
        return request(`devices/${params.id}/time_status_variety/?days=7`, {
          method: 'GET',
        });
      case 'dates':
        return request(`devices/${params.id}/time_status_variety/?interval=${params.dateRange[0]}~${params.dateRange[1]}`, {
          method: 'GET',
        });
      default: break;
    }
  },
  // 3.2 单机设备报警记录表格数据 api
  getDeviceAlarm(params) {
    switch (params.type) {
      case 'week':
        return request(`devices/${params.id}/alarm/?page=${params.page}&size=${params.size}&days=7`, {
          method: 'GET',
        });
      case 'month':
        return request(`devices/${params.id}/alarm/?page=${params.page}&size=${params.size}&days=30`, {
          method: 'GET',
        });
      case 'year':
        return request(`devices/${params.id}/alarm/?page=${params.page}&size=${params.size}&days=90`, {
          method: 'GET',
        });
      default:
        return request(`devices/${params.id}/alarm/?page=${params.page}&size=${params.size}`, {
          method: 'GET',
        });
    }
  },
  // 单机设备报警记录chart数据 api
  getAlarmChart(params) {
    switch (params.type) {
      case 'today':
        return request(`devices/${params.id}/alarm_record/?cnc_id=${params.cnc_id}`, {
          method: 'GET',
        });
      case'week':
        return request(`devices/${params.id}/alarm_record/?cnc_id=${params.cnc_id}&days=7`, {
          method: 'GET',
        });
      case 'month':
        return request(`devices/${params.id}/alarm_record/?cnc_id=${params.cnc_id}&days=30`, {
          method: 'GET',
        });
      case 'year':
        return request(`devices/${params.id}/alarm_record/?cnc_id=${params.cnc_id}&days=90`, {
          method: 'GET',
        });
      case 'dates':
        return request(
          `devices/${params.id}/alarm_record/?cnc_id=${params.cnc_id}&interval=${params.dateRange[0]}~${params.dateRange[1]}`, {
            method: 'GET',
          });
    }
  },

  // 单机设备维修记录chart数据 api
  getServiceChart(params) {
    switch (params.type) {
      case 'week':
        return request(`devices/${params.id}/service_record/?days=7`, {
          method: 'GET',
        });
      case 'month':
        return request(`devices/${params.id}/service_record/?days=30`, {
          method: 'GET',
        });
      case 'year':
        return request(`devices/${params.id}/service_record/?days=90`, {
          method: 'GET',
        });
      case 'dates':
        return request( `devices/${params.id}/service_record/?interval=${params.dateRange[0]}~${params.dateRange[1]}`, {
          method: 'GET',
        });
    }
  },

  // 异步请求人员报修记录表格数据 api
  getDeviceDefault(params) {
    return request(`devices/service/tasks/?${stringify(params)}`, {
      method: 'GET',
    });
  },
  getDeviceDefaultSearch(params) {
    return request(`devices/service/tasks/?${stringify(params)}`, {
      method: 'GET',
    });
  },
  createDeviceDefault(params) {
    return request('devices/service/tasks/', {
      method: 'POST',
      body: params,
    });
  },
  updateDeviceDefault(params) {
    return request(`devices/service/tasks/${params.id}/`, {
      method: 'PATCH',
      body: params,
    });
  },
  deleteDeviceDefault(params) {
    return request(`devices/service/tasks/${params.id}/`, {
      method: 'DELETE',
      body: params,
    });
  },

  // 单机设备维修记录表格数据 （查改删）
  getDeviceService(params) {
    return request(`devices/${params.id}/service/`, {
      method: 'GET',
    });
  },
  creatDeviceService(params) {
    return request(`devices/service/`, {
      method: 'POST',
      body: params,
    });
  },
  updateDeviceService(params) {
    // 维修记录的id
    return request(`devices/service/${params.id}/`, {
      method: 'PATCH',
      body: params,
    });
  },
  deleteDeviceService(params) {
    // 维修记录的id
    return request(`devices/service/${params.id}/`, {
      method: 'DELETE',
    });
  },

  // 异步请求人员维修记录表格数据（增删改查）
  getDeviceMaintain() {
    return request(`devices/service/`, {
      method: 'GET',
    });
  },
  // 异步请求维修记录表格增删改
  creatDeviceMaintain(params) {
    return request(`devices/service/`, {
      method: 'POST',
      body: params,
    });
  },
  updateDeviceMaintain(params) {
    return request(`devices/service/${params.id}/`, {
      method: 'PATCH',
      body: params,
    });
  },
  deleteDeviceMaintain(params) {
    return request(`devices/service/${params.id}/`, {
      method: 'DELETE',
    });
  },


  // 大屏新增接口
  // 累计加工时间、空闲时间、报警时间（近30天）
  getDeviceWorkTime(params) {
    return request(`company/device_accumulation_time/?status=${params.status}`, {
      method: 'GET',
    });
  },
  getDeviceIdleTime(params) {
    return request(`company/device_accumulation_time/?status=${params.status}`, {
      method: 'GET',
    });
  },
  getDeviceAlarmTime(params) {
    return request(`company/device_accumulation_time/?status=${params.status}`, {
      method: 'GET',
    });
  },
  // 累计加工件数（近90天）
  getDeviceAccumulationCount() {
    return request(`company/device_accumulation_count/`, {
      method: 'GET',
    });
  },

  //查看生产单元状态
  getUnitsStatus(params){
    return request(`api/units/${params.id}/status/`,{
      method: 'GET',
    })
  }
};
