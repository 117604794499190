import request from '@/utils/request';

export default{
  // 登陆接口
  fakeAccountLogin(params) {
    return request('auth', {
      method: 'POST',
      body: params,
      noUseEnterPriseID: true,
    });
  },

  // 通过signature获取token
  getTokenBySignature(params) {
    return request('user/unsign/', {
      method: 'POST',
      body: params,
      needToken: 'false',
      noUseEnterPriseID: true,
    });
  },

  // 获取登录人员信息
  fetchUserInfo() {
    return request('user/', {
      method: 'GET',
      noUseEnterPriseID: true,
    });
  },
  // 获取登录人员上下线信息
  fetchUserStatusInfo() {
    return request('users/status/', {
      method: 'GET',
    });
  },

  // 获取验证码
  checkPhone(params) {
    return request('user/send_code/', {
      method: 'POST',
      body: params,
      needToken: 'false',
      noUseEnterPriseID: true,
    });
  },

  // 提交修改密码
  changePwdSubmit(params) {
    return request('user/validation_code/', {
      method: 'POST',
      body: params,
      needToken: 'false',
      noUseEnterPriseID: true,
    });
  }
}
