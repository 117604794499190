// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/builds/kws/ui/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/components/PageLoading/index.js';

export function getRoutes() {
  const routes = [
  {
    "path": "/user",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__UserLayout' */'/builds/kws/ui/src/layouts/UserLayout'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/user",
        "redirect": "/user/login",
        "exact": true
      },
      {
        "path": "/user/login",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__User__Login' */'/builds/kws/ui/src/pages/User/Login'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/user/forgot-password",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__User__ForgotPwd' */'/builds/kws/ui/src/pages/User/ForgotPwd'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  },
  {
    "path": "/overview",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__BlankLayout' */'/builds/kws/ui/src/layouts/BlankLayout'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/overview/screen-data1",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__BigScreen__ScreenData1' */'/builds/kws/ui/src/pages/BigScreen/ScreenData1'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/overview/data-screen1",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__BigScreen__DataScreen1' */'/builds/kws/ui/src/pages/BigScreen/DataScreen1'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/overview/data-screen2",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__BigScreen__DataScreen2' */'/builds/kws/ui/src/pages/BigScreen/DataScreen2'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/overview/data-screen3",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__BigScreen__DataScreen3' */'/builds/kws/ui/src/pages/BigScreen/DataScreen3'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/overview/data-screen4",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__BigScreen__DataScreen4' */'/builds/kws/ui/src/pages/BigScreen/DataScreen4'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/overview/data-screen5",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__BigScreen__DataScreen5' */'/builds/kws/ui/src/pages/BigScreen/DataScreen5'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/overview/data-screen6",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__BigScreen__DataScreen6' */'/builds/kws/ui/src/pages/BigScreen/DataScreen6'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/overview/data-screen7",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__BigScreen__DataScreen7' */'/builds/kws/ui/src/pages/BigScreen/DataScreen7'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/overview/data-screen8",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__BigScreen__DataScreen8' */'/builds/kws/ui/src/pages/BigScreen/DataScreen8'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/overview/data-screensList",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__BigScreen__ScreenCarousel' */'/builds/kws/ui/src/pages/BigScreen/ScreenCarousel'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  },
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__BasicLayout' */'/builds/kws/ui/src/layouts/BasicLayout'), loading: LoadingComponent}),
    "Routes": [
      "src/pages/Authorized"
    ],
    "routes": [
      {
        "path": "/",
        "redirect": "/user/login",
        "exact": true
      },
      {
        "path": "/profile",
        "name": "个人信息",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Global__Center' */'/builds/kws/ui/src/pages/Global/Center'), loading: LoadingComponent}),
        "hideInMenu": true,
        "exact": true
      },
      {
        "path": "/information",
        "name": "微信通知",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Global__Information' */'/builds/kws/ui/src/pages/Global/Information'), loading: LoadingComponent}),
        "hideInMenu": true,
        "exact": true
      },
      {
        "path": "/403",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Exception__403' */'/builds/kws/ui/src/pages/Exception/403'), loading: LoadingComponent}),
        "hideInMenu": true,
        "exact": true
      },
      {
        "path": "/404",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Exception__404' */'/builds/kws/ui/src/pages/Exception/404'), loading: LoadingComponent}),
        "hideInMenu": true,
        "exact": true
      },
      {
        "path": "/500",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Exception__500' */'/builds/kws/ui/src/pages/Exception/500'), loading: LoadingComponent}),
        "hideInMenu": true,
        "exact": true
      },
      {
        "path": "/device-status/",
        "name": "设备状态",
        "icon": "dashboard",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Manufacturer__MakerStatus' */'/builds/kws/ui/src/pages/Manufacturer/MakerStatus'), loading: LoadingComponent}),
        "authority": [
          "administrator",
          "salesman",
          "serviceman"
        ],
        "exact": true
      },
      {
        "path": "/device-status/:id",
        "name": "设备详情",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Manufacturer__MakerStatusDetail' */'/builds/kws/ui/src/pages/Manufacturer/MakerStatusDetail'), loading: LoadingComponent}),
        "authority": [
          "administrator",
          "salesman",
          "serviceman"
        ],
        "hideInMenu": true,
        "exact": true
      },
      {
        "path": "/data-center",
        "name": "数据中心",
        "icon": "form",
        "routes": [
          {
            "name": "数据总览",
            "path": "/data-center/overview",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Manufacturer__MakerOverview' */'/builds/kws/ui/src/pages/Manufacturer/MakerOverview'), loading: LoadingComponent}),
            "authority": [
              "administrator"
            ],
            "exact": true
          },
          {
            "name": "报警分析",
            "path": "/data-center/analysis",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Manufacturer__MakerAnalysis' */'/builds/kws/ui/src/pages/Manufacturer/MakerAnalysis'), loading: LoadingComponent}),
            "authority": [
              "administrator"
            ],
            "exact": true
          }
        ]
      },
      {
        "path": "/system-manage",
        "name": "系统管理",
        "icon": "table",
        "routes": [
          {
            "path": "/system-manage/person",
            "name": "人员管理",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Manufacturer__MakerPerson' */'/builds/kws/ui/src/pages/Manufacturer/MakerPerson'), loading: LoadingComponent}),
            "authority": [
              "administrator"
            ],
            "exact": true
          },
          {
            "path": "/system-manage/device",
            "name": "设备管理",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Manufacturer__MakerDevice' */'/builds/kws/ui/src/pages/Manufacturer/MakerDevice'), loading: LoadingComponent}),
            "authority": [
              "administrator"
            ],
            "exact": true
          },
          {
            "path": "/system-manage/application",
            "name": "应用管理",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Manufacturer__MakerApp' */'/builds/kws/ui/src/pages/Manufacturer/MakerApp'), loading: LoadingComponent}),
            "authority": [
              "administrator"
            ],
            "exact": true
          },
          {
            "path": "/system-manage/data-screens",
            "name": "大屏管理",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Manufacturer__MakerScreens' */'/builds/kws/ui/src/pages/Manufacturer/MakerScreens'), loading: LoadingComponent}),
            "authority": [
              "administrator"
            ],
            "exact": true
          },
          {
            "path": "/system-manage/system-set",
            "name": "系统设置",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Global__SystemSet' */'/builds/kws/ui/src/pages/Global/SystemSet'), loading: LoadingComponent}),
            "authority": [
              "administrator"
            ],
            "exact": true
          }
        ]
      },
      {
        "path": "/main-page",
        "name": "主界面",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Status__MainPage' */'/builds/kws/ui/src/pages/Status/MainPage'), loading: LoadingComponent}),
        "hideInMenu": true,
        "exact": true
      },
      {
        "path": "/status",
        "name": "设备状态",
        "icon": "dashboard",
        "routes": [
          {
            "path": "/status/device-view",
            "name": "设备总览",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Status__DevOverview' */'/builds/kws/ui/src/pages/Status/DevOverview'), loading: LoadingComponent}),
            "authority": [
              "manager",
              "devadmin"
            ],
            "exact": true
          },
          {
            "path": "/status/product-lines",
            "name": "车间/产线",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Status__ProductLine' */'/builds/kws/ui/src/pages/Status/ProductLine'), loading: LoadingComponent}),
            "authority": [
              "manager",
              "devadmin",
              "devmain"
            ],
            "isStandard": true,
            "exact": true
          },
          {
            "path": "/status/product-lines/:id",
            "name": "产线详情",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Status__ProductLineDetail' */'/builds/kws/ui/src/pages/Status/ProductLineDetail'), loading: LoadingComponent}),
            "authority": [
              "manager",
              "devadmin",
              "devmain"
            ],
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/status/units",
            "name": "全部设备",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Status__MachineNC' */'/builds/kws/ui/src/pages/Status/MachineNC'), loading: LoadingComponent}),
            "authority": [
              "manager",
              "devadmin",
              "devmain"
            ],
            "exact": true
          },
          {
            "path": "/status/units/:id",
            "name": "单机详情",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Status__MachineNCDetail' */'/builds/kws/ui/src/pages/Status/MachineNCDetail'), loading: LoadingComponent}),
            "authority": [
              "manager",
              "devadmin",
              "devmain",
              "operator"
            ],
            "hideInMenu": true,
            "exact": true
          }
        ]
      },
      {
        "path": "/produce-task",
        "name": "生产任务",
        "icon": "form",
        "isStandard": true,
        "routes": [
          {
            "path": "/produce-task/orders",
            "name": "我的订单",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Task__MyOrder' */'/builds/kws/ui/src/pages/Task/MyOrder'), loading: LoadingComponent}),
            "authority": [
              "manager",
              "orderadmin"
            ],
            "exact": true
          },
          {
            "path": "/produce-task/orders/:id",
            "name": "订单详情",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Task__MyOrderDetail' */'/builds/kws/ui/src/pages/Task/MyOrderDetail'), loading: LoadingComponent}),
            "authority": [
              "manager",
              "orderadmin"
            ],
            "hideInMenu": true,
            "exact": true
          },
          {
            "path": "/produce-task/tasks",
            "name": "我的任务",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Task__MyTask' */'/builds/kws/ui/src/pages/Task/MyTask'), loading: LoadingComponent}),
            "authority": [
              "manager",
              "devadmin"
            ],
            "exact": true
          },
          {
            "path": "/produce-task/tasks/:id",
            "name": "任务详情",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Task__MyTaskDetail' */'/builds/kws/ui/src/pages/Task/MyTaskDetail'), loading: LoadingComponent}),
            "authority": [
              "manager",
              "devadmin",
              "operator"
            ],
            "hideInMenu": true,
            "exact": true
          }
        ]
      },
      {
        "path": "/data",
        "name": "数据中心",
        "icon": "table",
        "routes": [
          {
            "path": "/data/statistic",
            "name": "产量统计",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Data__Statistic' */'/builds/kws/ui/src/pages/Data/Statistic'), loading: LoadingComponent}),
            "authority": [
              "manager",
              "sysmain"
            ],
            "exact": true
          }
        ]
      },
      {
        "path": "/system",
        "name": "系统管理",
        "icon": "setting",
        "routes": [
          {
            "path": "/system/person",
            "name": "人员管理",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Data__Person' */'/builds/kws/ui/src/pages/Data/Person'), loading: LoadingComponent}),
            "authority": [
              "manager",
              "sysmain"
            ],
            "exact": true
          },
          {
            "path": "/system/workshop",
            "name": "车间管理",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Data__Workshop' */'/builds/kws/ui/src/pages/Data/Workshop'), loading: LoadingComponent}),
            "authority": [
              "manager",
              "sysmain",
              "devadmin"
            ],
            "exact": true
          },
          {
            "path": "/system/device",
            "name": "设备管理",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Data__Device' */'/builds/kws/ui/src/pages/Data/Device'), loading: LoadingComponent}),
            "authority": [
              "manager",
              "sysmain",
              "devadmin"
            ],
            "exact": true
          },
          {
            "path": "/system/product-line",
            "name": "产线管理",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Data__ProductLine' */'/builds/kws/ui/src/pages/Data/ProductLine'), loading: LoadingComponent}),
            "authority": [
              "manager",
              "sysmain",
              "devadmin"
            ],
            "isStandard": true,
            "exact": true
          },
          {
            "path": "/system/application",
            "name": "应用管理",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Data__Application' */'/builds/kws/ui/src/pages/Data/Application'), loading: LoadingComponent}),
            "authority": [
              "manager",
              "sysmain",
              "bridgemain"
            ],
            "isStandard": true,
            "exact": true
          },
          {
            "path": "/system/net-config",
            "name": "网关配置",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Data__NetConfig' */'/builds/kws/ui/src/pages/Data/NetConfig'), loading: LoadingComponent}),
            "authority": [
              "manager",
              "sysmain",
              "bridgemain"
            ],
            "exact": true
          },
          {
            "path": "/system/client",
            "name": "客户管理",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Data__Client' */'/builds/kws/ui/src/pages/Data/Client'), loading: LoadingComponent}),
            "authority": [
              "manager",
              "sysmain"
            ],
            "isStandard": true,
            "exact": true
          },
          {
            "path": "/system/data-screens",
            "name": "大屏管理",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Data__DataScreens' */'/builds/kws/ui/src/pages/Data/DataScreens'), loading: LoadingComponent}),
            "authority": [
              "manager",
              "sysmain"
            ],
            "exact": true
          },
          {
            "path": "/system/shift-manager",
            "name": "班次管理",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Data__ShiftManager' */'/builds/kws/ui/src/pages/Data/ShiftManager'), loading: LoadingComponent}),
            "authority": [
              "manager",
              "sysmain"
            ],
            "isStandard": true,
            "exact": true
          },
          {
            "path": "/system/tool-manager",
            "name": "刀具管理",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Data__ToolManager' */'/builds/kws/ui/src/pages/Data/ToolManager'), loading: LoadingComponent}),
            "authority": [
              "manager",
              "sysmain"
            ],
            "isStandard": true,
            "exact": true
          },
          {
            "path": "/system/set",
            "name": "系统设置",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Global__SystemSet' */'/builds/kws/ui/src/pages/Global/SystemSet'), loading: LoadingComponent}),
            "authority": [
              "manager",
              "sysmain"
            ],
            "exact": true
          }
        ]
      },
      {
        "path": "/productions",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Data__Production' */'/builds/kws/ui/src/pages/Data/Production'), loading: LoadingComponent}),
        "name": "产品管理",
        "icon": "bell",
        "authority": [
          "manager",
          "sysmain",
          "prodadmin"
        ],
        "isStandard": true,
        "exact": true
      },
      {
        "path": "/productions/:id",
        "name": "产品详情",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Data__ProductionDetail' */'/builds/kws/ui/src/pages/Data/ProductionDetail'), loading: LoadingComponent}),
        "hideInMenu": true,
        "authority": [
          "manager",
          "sysmain",
          "prodadmin"
        ],
        "exact": true
      },
      {
        "path": "/workbench",
        "name": "工作台",
        "icon": "form",
        "routes": [
          {
            "path": "/workbench/task-missions",
            "name": "执行任务",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Workbench__TaskMission' */'/builds/kws/ui/src/pages/Workbench/TaskMission'), loading: LoadingComponent}),
            "authority": [
              "operator"
            ],
            "exact": true
          },
          {
            "path": "/workbench/task-maintain",
            "name": "维修任务",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Workbench__TaskMaintain' */'/builds/kws/ui/src/pages/Workbench/TaskMaintain'), loading: LoadingComponent}),
            "authority": [
              "devadmin",
              "devmain"
            ],
            "exact": true
          },
          {
            "path": "/workbench/my-devices",
            "name": "我的设备",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Workbench__MyDevice' */'/builds/kws/ui/src/pages/Workbench/MyDevice'), loading: LoadingComponent}),
            "authority": [
              "devadmin",
              "devmain",
              "operator"
            ],
            "exact": true
          },
          {
            "path": "/workbench/production-log/workbench/production-logs",
            "name": "生产日志",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Workbench__ProductionLog' */'/builds/kws/ui/src/pages/Workbench/ProductionLog'), loading: LoadingComponent}),
            "authority": [
              "operator"
            ],
            "exact": true
          },
          {
            "path": "/workbench/operation-logs",
            "name": "工作日志",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Workbench__OperationLog' */'/builds/kws/ui/src/pages/Workbench/OperationLog'), loading: LoadingComponent}),
            "authority": [
              "devadmin",
              "devmain"
            ],
            "exact": true
          }
        ]
      },
      {
        "path": "/enter-list",
        "name": "企业管理",
        "icon": "form",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Data__ServerList' */'/builds/kws/ui/src/pages/Data/ServerList'), loading: LoadingComponent}),
        "authority": [
          "admin"
        ],
        "exact": true
      },
      {
        "path": "/person-list",
        "name": "账户管理",
        "icon": "table",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Data__AccountList' */'/builds/kws/ui/src/pages/Data/AccountList'), loading: LoadingComponent}),
        "authority": [
          "admin"
        ],
        "exact": true
      },
      {
        "path": "/remote-connect",
        "name": "远程协助请求",
        "icon": "link",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Data__RemoteConnect' */'/builds/kws/ui/src/pages/Data/RemoteConnect'), loading: LoadingComponent}),
        "authority": [
          "manager",
          "administrator",
          "serveradmin",
          "engineer"
        ],
        "exact": true
      },
      {
        "path": "/service/person",
        "name": "人员管理",
        "icon": "table",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Facilitator__ServicePerson' */'/builds/kws/ui/src/pages/Facilitator/ServicePerson'), loading: LoadingComponent}),
        "authority": [
          "serveradmin"
        ],
        "exact": true
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/builds/kws/ui/src/pages/404'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Exception__404' */'/builds/kws/ui/src/pages/Exception/404'), loading: LoadingComponent})
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
