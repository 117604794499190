import request from '../utils/request';
import { stringify } from 'qs';

export default {
  // 异步请求个人信息
  fetchCurrent() {
    return request(`user/profile/`, {
      method: 'GET',
      noUseEnterPriseID: true,
    });
  },

  // 异步修改个人信息
  patchCurrent(params) {
    return request(`user/profile/`, {
      method: 'PATCH',
      body: params,
      noUseEnterPriseID: true,
    });
  },

  // 微信解绑
  handleWxUnbind() {
    return request(`disconnect/weixinapp/`, {
      method: 'POST',
      noUseEnterPriseID: true,
    });
  },

  // 异步请求企业信息
  fetchCompany(params) {
    return request(`enterprises/${params.id}/`, {
      method: 'GET',
      noUseEnterPriseID: true,
    });
  },

  // 异步修改企业信息
  patchCompany(params) {
    return request(`enterprises/${params.id}/`, {
      method: 'PATCH',
      body: params,
      noUseEnterPriseID: true,
    });
  },

  //企业延期
  extensionTime(){
    return request(`apply_extension`, {
      method: 'POST',
    });
  },

  // 异步获取企业应用列表
  getEnterAppList(params) {
    return request(`third_party_applications/?${stringify(params)}`, {
      method: 'GET',
    });
  },
  createEnterApp(params) {
    return request('third_party_applications/', {
      method: 'POST',
      body: params,
    });
  },
  deleteEnterApp(id) {
    return request(`third_party_applications/${id}/`, {
      method: 'DELETE',
    });
  },
  updateEnterApp(params) {
    return request(`third_party_applications/${params.id}/`, {
      method: 'PATCH',
      body: params,
    });
  },

  // 异步查询个人账户手机号重复验证
  UserCheck(params) {
    return request(`user/exists?${stringify(params)}`, {
      method: 'GET',
      noUseEnterPriseID: true,
    });
  },

  // 异步请求个人账户手机号验证码
  getCaptcha(params) {
    return request(`user/send_bind_phone?${stringify(params)}`, {
      method: 'GET',
      noUseEnterPriseID: true,
    });
  },

  // 异步请求个人账户修改手机号
  changeBindPhone(params) {
    return request('user/change_bind_phone', {
      method: 'PATCH',
      body: params,
      noUseEnterPriseID: true,
    });
  },

}
