import service from '../services/task';

export default {
  namespace: 'task',
  // 模型
  state: {
    missionData: [],
  },

  // 异步方法，主要从定义异步请求
  effects: {
    // 异步查看订单剩余件数
    *pieceRemain({ payload }, { call, put }) {
      const result = yield call(service.getPieceRemain, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'savePieceRemain',
        result,
      });
    },

    // 异步请求任务分页列表
    *fetch({ payload }, { call, put }) {
      const result = yield call(service.getTaskList, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      const data = result.results;
      const count = result.count;
      yield put({
        type: 'saveList',
        data,
        count,
      });
    },
    // 异步请求任务所有列表
    *getAllTaskList({ payload }, { call, put }) {
      const result = yield call(service.getAllTaskList, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveAllList',
        result,
      });
    },

    // 异步检查任务编号重复
    *checkTaskNumber({ payload }, { call, put }) {
      const result = yield call(service.checkTaskNumber, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'checkTaskNumberList',
        result,
      });
      return result;
    },
    // 异步新增任务列表
    *create({ payload }, { call, put }) {
      const result = yield call(service.createTask, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'createList',
        result,
      });
    },
    // 异步删除任务列表
    *delete({ payload }, { call, put }) {
      const result = yield call(service.getTaskDelete, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      if (result) {
        yield put({
          type: 'deleteList',
          result: payload,
        });
      }
    },
    // 异步更新任务列表
    *update({ payload }, { call, put }) {
      const result = yield call(service.getTaskUpdate, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'updateList',
        result,
      });
    },
    // 异步查看任务列表
    *detail({ payload }, { call, put }) {
      const result = yield call(service.getTaskDetail, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'detailList',
        result,
      });
    },
    // 异步查看任务进度
    *taskSpeedData({ payload }, { call, put }) {
      const result = yield call(service.taskSpeedData, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'taskSpeedList',
        result,
      });
    },
    // 异步修改任务进度
    *updateTaskSpeed({ payload }, { call, put }) {
      const result = yield call(service.updateTaskSpeed, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'updateTaskSpeedList',
        result,
      });
    },
    // 异步查看测量表数据
    *getTaskMeasureData({ payload }, { call, put }) {
      const result = yield call(service.getTaskMeasureData, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      // yield put({
      //   type: 'taskMeasureList',
      //   result,
      // });
      return result;
    },
    // 异步修改测量表数据
    *updateTaskMeasureData({ payload }, { call, put }) {
      const result = yield call(service.updateTaskMeasureData, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'updateTaskMeasureList',
        result,
      });
    },
    //异步删除测量表数据
    *deleteTaskMeasureData({ payload }, { call, put }) {
      const result = yield call(service.deleteTaskMeasureData, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      if (result) {
        yield put({
          type: 'deleteTaskMeasureList',
          result: payload,
        });
      }
    },
    // 异步请求订单分页列表
    *getOrderList({ payload }, { call, put }) {
      const result = yield call(service.getOrderList, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      const data = result.results;
      const count = result.count;
      yield put({
        type: 'saveOrderList',
        data,
        count,
      });
    },
    // 异步请求订单所有列表
    *getAllOrderList({ payload }, { call, put }) {
      const result = yield call(service.getAllOrderList, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveAllOrderList',
        result,
      });
    },
    // 异步新增订单列表
    *createOrder({ payload }, { call, put }) {
      yield call(service.createOrder, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
    },
    // 异步更新订单列表
    *updateOrder({ payload }, { call, put }) {
      const result = yield call(service.updateOrder, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      return result
    },
    // 异步删除订单列表
    *deleteOrder({ payload }, { call, put }) {
      yield call(service.deleteOrder, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
    },
    // 异步查看订单列表
    *orderDetail({ payload }, { call, put }) {
      const result = yield call(service.orderDetail, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'detailOrderList',
        result,
      });
      return result;
    },
    // 异步查看订单进度
    *orderSpeedData({ payload }, { call, put }) {
      const result = yield call(service.orderSpeedData, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'orderSpeedList',
        result,
      });
    },
    // 订单编号重复验证
    *OrderNumTest({ payload }, { call, put }) {
      const result = yield call(service.OrderNumTest, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      if (result) {
        yield put({
          type: 'OrderNumTestRes',
          result,
        });
      }
    },

    // 查看操作人员动态
    *getUserTrends({ payload }, { call, put }) {
      const result = yield call(service.getUserTrends, payload);
      yield put({
        type: 'saveUserTrends',
        result,
      });
      return result;
    },

    // 操作员权限接口
    // 获取工作台任务日志
    *getTaskLogList({ payload }, { call, put }) {
      const result = yield call(service.getTaskLogList, payload);
      yield put({
        type: 'saveTaskLog',
        result,
      });
    },
    // 异步新增任务日志
    *createTaskLog({ payload }, { call, put }) {
      const result = yield call(service.createTaskLog, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'createTaskLogList',
        result,
      });
      return result;
    },
    // 异步更新任务日志
    *updateTaskLog({ payload }, { call, put }) {
      const result = yield call(service.updateTaskLog, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'updateTaskLogList',
        result,
      });
    },
    // 异步删除任务日志
    *deleteTaskLog({ payload }, { call, put }) {
      const result = yield call(service.deleteTaskLog, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      if (result) {
        yield put({
          type: 'deleteTaskLogList',
          result: payload,
        });
      }
    },


    // 管理员权限接口
    // 获取订单任务列表
    *getOrderTasksList({ payload }, { call, put }) {
      const result = yield call(service.getOrderTasksList, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      const data = result.results;
      const count = result.count;
      yield put({
        type: 'saveOrderTasksList',
        data,
        count,
      });
      return result;
    },
    // 获取订单日志列表
    *getOrderLogData({ payload }, { call, put }) {
      const result = yield call(service.getOrderLogData, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      const data = result.results;
      const count = result.count;
      yield put({
        type: 'saveOrderLogData',
        data,
        count,
      });
    },
    // 获取任务日志列表
    *getTaskLogData({ payload }, { call, put }) {
      const result = yield call(service.getTaskLogData, payload);
      const data = result.results;
      const count = result.count;
      yield put({
        type: 'saveTaskLogDataList',
        data,
        count,
      });
    },
    // 修改任务日志列表
    *updateTaskLogData({ payload }, { call, put }) {
      const result = yield call(service.updateTaskLogData, payload);
      if (result) {
        yield put({
          type: 'updateTaskLogDataList',
          result,
        });
      }
    },

    // 大屏新增接口
    // 异步请求即将交付订单列表
    *getDeadlineOrderList({ payload }, { call, put }) {
      const result = yield call(service.getDeadlineOrderList, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      const data = result.results;
      yield put({
        type: 'saveDeadlineOrder',
        data,
      });
    },
    // 异步请求即将过期任务列表
    *getDeadlineTaskList({ payload }, { call, put }) {
      const result = yield call(service.getDeadlineTaskList, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      const data = result.results;
      yield put({
        type: 'saveDeadlineTask',
        data,
      });
    },
    // 获取90天内订单状态
    *getOrderStatus({ payload }, { call, put }) {
      const result = yield call(service.getOrderStatus, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveOrderStatus',
        result,
      });
    },
    // 获取30天内任务状态
    *getTaskStatus({ payload }, { call, put }) {
      const result = yield call(service.getTaskStatus, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveTaskStatus',
        result,
      });
    },

    // 异步30天内任务列表
    *getMonthTaskList({ payload }, { call, put }) {
      const result = yield call(service.getMonthTaskList, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      const data = result.results;
      yield put({
        type: 'saveMonthTaskList',
        data,
      });
    },
    // 异步30天内任务数量统计
    *getMonthTaskCount({ payload }, { call, put }) {
      const result = yield call(service.getMonthTaskCount, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveMonthTaskCount',
        result,
      });
    },

    // 异步任务件数下发cnc
    *createCncCount({ payload }, { call, put }) {
      const result = yield call(service.createCncCount, payload); // 通过yield暂时交出线程执行权，结果返回之后再继续执行
      yield put({
        type: 'saveCncCount',
        result,
      });
      return result;
    },
  },

  // 同步方法，主要讲数据更新到state中
  reducers: {
    savePieceRemain(state, { result: PieceRemainData }) {
      return {
        ...state,
        PieceRemainData,
      };
    },

    // 任务 Task
    saveList(state, { data: missionData, count }) {
      return {
        ...state,
        missionData,
        count,
      };
    },
    saveAllList(state, { result: data }) {
      const MissionDataS = data.filter(item => item.task_number);
      return {
        ...state,
        MissionDataS,
      };
    },
    createList(state, { result: missionData }) {
      state.missionData.unshift(missionData);
      // unshift 不返回新数组，直接修改并赋值原missionData。此处missionData为新增数据。不是state中的missionData
      return {
        ...state,
      };
    },
    deleteList(state, { result: payload }) {
      state.missionData.filter(item => item.id !== payload);
      // filter 返回新数组newMissionData，并将新数组赋值给页面要展现的missionData
      return {
        ...state,
      };
    },
    deleteTaskMeasureList(state, { result: payload }) {
      state.taskMeasureListData.filter(item => item.id !== payload);
      // filter 返回新数组newMissionData，并将新数组赋值给页面要展现的missionData
      return {
        ...state,
      };
    },
    updateList(state, { result }) {
      const taskUpdateMes = result.error;
      state.missionData = state.missionData.map(item => {
        return item.id === result.id ? result : item;
      });
      return {
        ...state,
        taskUpdateMes,
      };
    },
    detailList(state, { result: missionDataOne }) {
      return {
        ...state,
        missionDataOne,
      };
    },
    taskSpeedList(state, { result: taskSpeedData }) {
      return {
        ...state,
        taskSpeedData,
      };
    },
    taskMeasureList(state, { result: taskMeasureListData }) {
      return {
        ...state,
        taskMeasureListData,
      };
    },
    updateTaskSpeedList(state, { result }) {
      const taskSpeedUpdateMes = result.error;
      return {
        ...state,
        taskSpeedUpdateMes,
      };
    },
    updateTaskMeasureList(state, { result }) {
      state.taskMeasureListData = state.taskMeasureListData.map(item => {
        return item.id === result.id ? result : item;
      });
      return { ...state };
    },
    // 订单 Order
    saveOrderList(state, { data: OrderData, count }) {
      return {
        ...state,
        OrderData,
        count,
      };
    },
    saveAllOrderList(state, { result: OrderDataS }) {
      return {
        ...state,
        OrderDataS,
      };
    },
    detailOrderList(state, { result: OrderDataOne }) {
      return {
        ...state,
        OrderDataOne,
      };
    },
    orderSpeedList(state, { result: orderSpeedData }) {
      return {
        ...state,
        orderSpeedData,
      };
    },
    // 订单编号重复验证
    OrderNumTestRes(state, { result: OrderNumTestData }) {
      return {
        ...state,
        OrderNumTestData,
      };
    },

    // 保存操作人员动态
    saveUserTrends(state, { result: userTrendsData }) {
      return {
        ...state,
        userTrendsData,
      };
    },

    // 操作员任务日志列表
    saveTaskLog(state, { result: TaskLogData }) {
      return {
        ...state,
        TaskLogData,
      };
    },
    createTaskLogList(state, { result: TaskLogData }) {
      state.TaskLogData = [TaskLogData].concat(state.TaskLogData);
      return {
        ...state,
      };
    },
    updateTaskLogList(state, { result }) {
      state.TaskLogData = state.TaskLogData.map(item => {
        return item.id === result.id ? result : item;
      });
      return { ...state };
    },
    deleteTaskLogList(state, { result: payload }) {
      const newTaskLogData = state.TaskLogData.filter(item => item.id !== payload);
      return {
        ...state,
        TaskLogData: newTaskLogData,
      };
    },

    // 管理员订单任务日志
    saveOrderLogData(state, { data: OrderDetailLogData, count }) {
      return {
        ...state,
        OrderDetailLogData,
        count,
      };
    },
    saveTaskLogDataList(state, { data: TaskDetailLogData, count }) {
      return {
        ...state,
        TaskDetailLogData,
        count,
      };
    },
    updateTaskLogDataList(state, { result }) {
      state.TaskDetailLogData = state.TaskDetailLogData.map(item => {
        return item.time === result.time ? result : item;
      });
      return {
        ...state,
      };
    },

    // 大屏新增接口
    // 异步请求即将交付订单列表
    saveDeadlineOrder(state, { data: DeadlineOrderData }) {
      return { ...state, DeadlineOrderData };
    },
    // 异步请求即将过期任务列表
    saveDeadlineTask(state, { data: DeadlineTaskData }) {
      return { ...state, DeadlineTaskData };
    },
    // 异步请求90天内订单状态
    saveOrderStatus(state, { result: OrderStatusData }) {
      return { ...state, OrderStatusData };
    },
    // 异步请求30天内任务状态
    saveTaskStatus(state, { result: TaskStatusData }) {
      return { ...state, TaskStatusData };
    },
    // 异步请求30天内任务列表
    saveMonthTaskList(state, { data: MonthTaskListData }) {
      return { ...state, MonthTaskListData };
    },
    // 异步请求30天内任务数量统计
    saveMonthTaskCount(state, { result: MonthTaskCountData }) {
      return { ...state, MonthTaskCountData };
    },


    /*
    直接修改原数组,不返回新数组：
    1.arr.splice();
    2.arr.reverse();
    3.arr.sort();
    4.arr.push(值);arr.pop();
    5.arr.unshift(值);arr.shift();

    不修改原数组,返回新数组：
    1.String(arr);
    2.arr.join("自定义分隔符");
    3.arr1.concat(值1,值2,arr2,...);
    4.arr.slice(starti,endi+1);
    */
  },
};
