import React from 'react';
import { message } from 'antd';
import { history } from 'umi';
import { Link, getLocale, setLocale, formatMessage, } from 'umi';
import CheckPermissions from './CheckPermissions';
import { connect } from 'dva'
@connect(({ login, global }) => ({
  login, global
}))
class Authorized extends React.Component {
  componentWillMount() {
    let url = window.location.href;
    let urlSplitArr = url.split('?');
    if (urlSplitArr.length > 1) {
      let key = urlSplitArr[1].split('=')[0];
      let signature = urlSplitArr[1].split('=')[1];

      if (key == 'signature') { // 产线总控跳转个人信息页面
        // 请求token
        this.props
          .dispatch({
            type: 'login/getTokenBySignature',
            payload: {
              signature: signature,
            }
          })
          .then(() => {
            // 通过返回的token去请求user和profile接口
            const response = this.props.login && this.props.login.SignatureToken;
            if (response && response.token) {
              localStorage.setItem('token', response.token);
              localStorage.setItem('tokenLogin', true);
              this.props.dispatch({
                type: 'login/fetchUserInfo',
                payload: {
                  redirectUrl: '/profile',
                }
              });
              this.props.dispatch({
                type: 'global/fetchCurrent',
              });
              return;
            } else {
              message.error("未获取到用户token，请重新登录");
              history.push({
                pathname: `/user/login`,
              });
            }
          })
      }
    }
  }

  render() {
    const { children, authority, noMatch = null } = this.props;
    const childrenRender = typeof children === 'undefined' ? null : children;
    return CheckPermissions(authority, childrenRender, noMatch);
  }
}

export default Authorized;
