//设置身份
export function setAuthority(authority) {
  return localStorage.setItem("group", authority);
  
}
//获取身
export function getAuthority() {
  return localStorage.getItem("group");
}

